import { useEffect, useState } from 'react';

const Countdown = ({ timestamp }) => {
  // State variable to store the time left until the timestamp
  const [timeLeft, setTimeLeft] = useState(timestamp - Date.now());
  useEffect(() => {
    // Update the time left every second
    const interval = setInterval(() => {
      setTimeLeft(timestamp - Date.now());
    }, 1000);

    // Clean up the interval when the component is unmounted or the timestamp changes
    return () => clearInterval(interval);
  }, [timestamp]);

  // Calculate the days, hours, minutes, and seconds from the time left
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  // Render the countdown timer or '00:00:00' if the current time is after the timestamp
  return Date.now() > timestamp ? (
    '00:00:00'
  ) : (
    <div>
      {days}d : {hours}h : {minutes}m : {seconds}s
    </div>
  );
};

export default Countdown;
