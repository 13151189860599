import React from 'react';
// standard functional component that displays policy information
const Policy = () => {
  return (
    <div className="container mx-auto py-8 text-white px-10  md:px-32 lg:px-64">
      <h1 className="text-3xl font-bold mb-4">
        Policy for The Universal Collective NFT Marketplace
      </h1>
      <p className="mb-4">
        Welcome to The Universal Collective NFT Marketplace ("the Marketplace").
        These Terms of Service ("Terms") govern your access to and use of the
        Marketplace. By accessing or using the Marketplace, you agree to be
        bound by these Terms. If you do not agree to these Terms, please do not
        access or use the Marketplace.
      </p>
      <p className="mb-4">
        Acceptance of Terms By accessing or using the Marketplace, you represent
        that you have read, understood, and agree to be bound by these Terms, as
        well as any additional guidelines or rules posted on the Marketplace.
        These Terms constitute a legally binding agreement between you and The
        Universal Collective.
      </p>
      <ul>
        <li>
          <h2 className="font-semibold underline">User Accounts</h2>
          <p>
            In order to use certain features of the Marketplace, you may need to
            create an account. You must provide accurate and complete
            information during the registration process and keep your account
            information up to date. You are responsible for maintaining the
            confidentiality of your account credentials and are solely
            responsible for all activities that occur under your account.
          </p>{' '}
          <br />{' '}
          <p>
            Age Restrictions By creating an account and using the Marketplace,
            you represent and warrant that you are at least 18 years old or have
            obtained the consent of a legal guardian if you are a minor in your
            jurisdiction.
          </p>
        </li>
        <li className="pt-4">
          <h2 className="font-semibold underline">NFT Transactions</h2>
          <p>
            Listing and Selling NFTs As a user of the Marketplace, you may list
            and sell NFTs subject to the guidelines and requirements set forth
            on the Marketplace. You represent and warrant that you have the
            legal rights to sell the NFTs you list on the Marketplace and that
            the NFTs do not infringe any third-party rights.
          </p>{' '}
          <br />
          <p>
            Buying NFTs When purchasing NFTs on the Marketplace, you agree to
            pay the listed price for the NFT. All transactions are final, and
            refunds or cancellations are at the sole discretion of the seller.
            The Universal Collective is not responsible for any disputes arising
            from NFT transactions conducted on the Marketplace.
          </p>
        </li>
        <li className="pt-4">
          <h2 className="font-semibold underline">Intellectual Property</h2>
          <p>
            Ownership of NFTs When you purchase an NFT on the Marketplace, you
            acquire ownership of the unique digital item represented by the NFT.
            However, The Universal Collective retains all intellectual property
            rights associated with the Marketplace, including its website,
            branding, and any content provided on the Marketplace.
          </p>{' '}
          <br />
          <p>
            User-Generated Content You retain ownership of any user-generated
            content you upload or submit to the Marketplace, but by doing so,
            you grant The Universal Collective a non-exclusive, worldwide,
            royalty-free license to use, reproduce, modify, adapt, publish, and
            display such content for the purposes of operating and promoting the
            Marketplace.
          </p>
        </li>
        <h2 className="font-semibold underline pt-4">Prohibited Activities</h2>
        <p>
          You agree not to engage in any of the following activities on the
          Marketplace:
        </p>{' '}
        <br />
        <ul className="pl-10">
          <li className="list-disc">
            Violating any applicable laws or regulations.
          </li>
          <li className="list-disc">
            Infringing upon the intellectual property rights of others.
          </li>
          <li className="list-disc">
            Distributing, transmitting, or facilitating the distribution of any
            harmful, offensive, or unlawful content.
          </li>
          <li className="list-disc">
            Interfering with or disrupting the operation of the Marketplace.
          </li>
          <li className="list-disc">
            Engaging in any fraudulent or deceptive practices..
          </li>
        </ul>
        <p className="pt-4">
          Termination The Universal Collective reserves the right to suspend or
          terminate your access to the Marketplace at any time, for any reason,
          without notice. In the event of termination, these Terms will continue
          to apply to any prior use of the Marketplace.
        </p>{' '}
        <br />
        <p>
          Limitation of Liability To the fullest extent permitted by law, The
          Universal Collective and its affiliates, directors, officers, employees,
          agents, and licensors shall not be liable for any indirect,
          incidental, special, consequential, or exemplary damages arising out
          of or in connection with the Marketplace.
        </p>
        <br />
        <p>
          Governing Law and Jurisdiction These Terms shall be governed by and
          construed in accordance with the laws of [Jurisdiction]. Any disputes
          arising from or relating to these Terms or the use of the Marketplace
          shall be subject to the exclusive jurisdiction of the courts located
          in [Jurisdiction].
        </p>
        <ul>
          <h2 className="font-semibold underline pt-4">
            Assumption of Risk and Limitation of Liability
          </h2>
          <p>
            Assumption of Risk By using the Marketplace and engaging in NFT
            transactions, you acknowledge and accept the inherent risks
            associated with blockchain technology, including but not limited to
            the potential for loss or theft of digital assets. You agree that
            you are solely responsible for assessing and assuming any risks
            associated with your use of the Marketplace.
          </p>{' '}
          <br />
          <p>
            No Responsibility for Loss The Universal Collective and its affiliates,
            directors, officers, employees, agents, and licensors shall not be
            responsible or liable for any losses, damages, or liabilities
            incurred by you in connection with your use of the Marketplace,
            including but not limited to any loss of funds, NFTs, or digital
            assets. You understand and agree that you use the Marketplace at
            your own risk.
          </p>
          <h2 className="font-semibold underline pt-4">
            No Financial or Legal Advice{' '}
          </h2>
          <p>
            The Universal Collective and its representatives do not provide
            financial or legal advice. Any information or content provided on
            the Marketplace is for informational purposes only and should not be
            considered as financial or legal advice. You should consult with
            your own professional advisors before making any decisions or
            engaging in any transactions on the Marketplace.
          </p>{' '}
          <br />
          <h2 className="font-semibold underline pt-4">Indemnification</h2>
          <p>
            {' '}
            You agree to indemnify, defend, and hold harmless The Universal
            Collective, its affiliates, directors, officers, employees, agents, and
            licensors from and against any claims, liabilities, damages, losses,
            and expenses, including reasonable attorneys' fees, arising out of
            or in any way connected with your use of the Marketplace, violation
            of these Terms, or infringement of any rights of third parties.
          </p>{' '}
          <br />
          <h2 className="font-semibold underline pt-4">Modifications </h2>
          <p>
            The Universal Collective reserves the right to modify or revise these
            Terms at any time, with or without notice. It is your responsibility
            to review these Terms periodically for any changes. By continuing to
            access or use the Marketplace after any modifications, you agree to
            be bound by the revised Terms..
          </p>{' '}
          <br />
          <p>
            Severability If any provision of these Terms is found to be
            unenforceable or invalid, that provision shall be limited or
            eliminated to the minimum extent necessary, and the remaining
            provisions of these Terms shall remain in full force and effect.
          </p>{' '}
          <br />
          <p>
            Entire Agreement These Terms constitute the entire agreement between
            you and The Universal Collective regarding the use of the Marketplace
            and supersede any prior agreements or understandings, whether
            written or oral.
          </p>
          <br />
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at [contact email].
          </p>
        </ul>
      </ul>
    </div>
  );
};
export default Policy;
