import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { purchaseNFT } from '../services/blockchain';
import { setGlobalState, useGlobalState } from '../store';
import Countdown from './Countdown';
import { deleteNFT } from '../services/api';
import { useState, useEffect } from 'react';

const Artworks = ({ auctions, title, showOffer, lazy }) => {
  return (
    <div className="relative w-full py-10 mx-auto justify-center ">
      <p className="text-xl  text-white mb-4">
        {title ? title : 'Current Auctions'}
      </p>
      <div
        className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6
        md:gap-4 lg:gap-3 py-2.5 text-white font-mono px-1 z-20"
      >
        {auctions.map((auction, i) => (
          <Auction
            key={i}
            auction={auction}
            showOffer={showOffer}
            lazy={lazy}
          />
        ))}
      </div>
    </div>
  );
};

const Auction = ({ auction, showOffer, lazy }) => {
  // Getting the value of "connectedAccount" from global state using useGlobalState hook
  const [connectedAccount] = useGlobalState('connectedAccount');
  
  const [nzdValue, setNzdValue] = useState('');

  useEffect(() => {
    // Fetch the NZD value from Coinbase API
    fetchNzdValue();
  }, []);

  const fetchNzdValue = async () => {
    try {
      const response = await fetch('https://api.coinbase.com/v2/prices/ETH-NZD/spot');
      const data = await response.json();
      const { amount } = data.data;
      setNzdValue(amount);
    } catch (error) {
      console.error('Error fetching NZD value:', error);
    }
  };

  const convertEthToNzd = (ethValue) => {
    const nzd = parseFloat(nzdValue) * parseFloat(ethValue);
    return nzd.toFixed(2);
  };

  const onChangePrice = () => {
    // Setting the value of "auction" in global state
    setGlobalState('auction', auction);
    // Setting the value of "priceModal" in global state
    setGlobalState('priceModal', 'scale-100');
  };

  const onOfferItem = () => {
    // Setting the value of "auction" in global state
    setGlobalState('auction', auction);
    // Setting the value of "offerModal" in global state
    setGlobalState('offerModal', 'scale-100');
  };

  const onPlaceBid = () => {
    // Setting the value of "auction" in global state
    setGlobalState('auction', auction);
    // Setting the value of "bidModal" in global state
    setGlobalState('bidModal', 'scale-100');
  };

  const handleDeleteNFT = async () => {
    await toast.promise(
      new Promise(async (resolve, reject) => {
        // Calling the deleteNFT function with the auction object converted to FormData
        await deleteNFT(objectToFormData(auction))
          .then(() => {
            // Reload the page after successful deletion
            window.location.reload();
            resolve();
          })
          .catch((error) => {
            alert(JSON.stringify(error));
            reject(error);
          });
      }),
      {
        // display on change
        pending: 'Deleting NFT...',
        success: 'NFT delted successfully...',
        error: 'Encoutered an error',
      }
    );
  };

  const objectToFormData = (obj) => {
    const formData = new FormData();
    for (let key in obj) {
      // Append each key-value pair from the object to the FormData
      formData.append(key, obj[key]);
    }
    return formData;
  };

  const handleNftPurchase = async () => {
    // Display a toast while transferring the NFT
    await toast.promise(
      new Promise(async (resolve, reject) => {
        // Calling the purchaseNFT function with the auction object
        await purchaseNFT(auction)
          // Resolve the promise after successful transfer
          .then(() => resolve())
          .catch((error) => {
            alert(JSON.stringify(error));
            reject(error);
          });
      }),
      {
        //display on change
        pending: 'Transfering NFT...',
        success: 'Transfer completed...',
        error: 'Encountered error',
      }
    );
  };

  return (
    <div
      className=" overflow-hidden bg-gray-800 rounded-md shadow-xl
    shadow-black md:mt-0 font-sans my-4"
    >
      <Link
        to={
          auction.tokenId
            ? `/nft/onchain/${auction.tokenId}`
            : `/nft/offchain/${auction.id}`
        }
      >
        <img
          src={auction.image}
          alt={auction.name}
          className="object-cover w-full h-60"
        />
      </Link>

      <div
        className="shadow-lg shadow-gray-400 border-4 border-[#ffffff36]
      flex justify-between items-center text-gray-300 px-2"
      >
        <div className="flex flex-col items-start py-2 px-1">
          <span>Current Bid</span>
          <div className="font-normal text-center text-sm ">{auction.price} ETH (${convertEthToNzd(auction.price)} NZD)</div>
        </div>

        <div className="flex flex-col items-start py-2 px-1">
          <span>Auction End</span>
          <div className="font-normal text-center">
            {/* {auction.live && auction.duration > Date.now() ? ( */}
            {auction.duration > Date.now() && !auction.sold ? (
              <Countdown timestamp={auction.duration} />
            ) : (
              '00:00:00'
            )}
          </div>
        </div>
      </div>

      {showOffer ? (
        auction.live && Date.now() < auction.duration ? (
          <button
            className="bg-yellow-500 w-full h-[40px] p-2 text-center
            font-bold font-mono"
          >
            Auction Live
          </button>
        ) : (
          <div className="flex justify-start">
            <button
              className="bg-red-500 w-full h-[40px] p-2 text-center
            font-bold font-mono"
              onClick={onOfferItem}
            >
              Offer
            </button>
            <button
              className="bg-orange-500 w-full h-[40px] p-2 text-center
            font-bold font-mono"
              onClick={onChangePrice}
            >
              Change
            </button>
          </div>
        )
      ) : lazy ? (
        <div className="flex justify-start">
          <button
            className="bg-orange-500 w-full h-[40px] p-2 text-center
          font-bold font-mono"
            onClick={onOfferItem}
          >
            Offer
          </button>

          <button
            className="bg-red-500 w-full h-[40px] p-2 text-center
            font-bold font-mono"
            onClick={handleDeleteNFT}
          >
            Delete
          </button>
        </div>
      ) : auction.biddable ? (
        <button
          className="bg-blue-500 w-full h-[40px] p-2
          text-center font-bold font-mono"
          onClick={onPlaceBid}
          disabled={!connectedAccount || connectedAccount == auction.seller}
        >
          Place a Bid
        </button>
      ) : (
        <button
          className="bg-red-500 w-full h-[40px] p-2
          text-center font-bold font-mono"
          onClick={handleNftPurchase}
          disabled={!connectedAccount || connectedAccount == auction.seller}
        >
          Buy NFT
        </button>
      )}

    </div>
 
  );
};

export default Artworks;
