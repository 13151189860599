import { Link } from 'react-router-dom';
import { connectWallet } from '../services/blockchain';
import { truncate, useGlobalState } from '../store';
import logo from '../assets/images/Smallwhite.png';
import React, { useState } from 'react';

const Header = () => {
  // State variables
  // Connected wallet account
  const [connectedAccount] = useGlobalState('connectedAccount');
  // Admin status
  const [admin] = useGlobalState('admin');
  // Navbar state
  const [navbar, setNavbar] = useState(false);
  // Function to close the navbar
  const closeNavbar = () => {
    setNavbar(false);
  };

  return (
    <div className="justify-between mx-auto lg:w-4/5 md:items-center md:flex ">
      <div className="flex items-center justify-between py-3 md:py-5 md:block z-20">
        <a href="/">
          <div className="flex items-center gap-6 p-8">
            <img
              src={logo}
              alt="search"
              className="w-[100px] h-[100px] object-contain z-10 "
            />
            <h2 className="text-2xl text-white font-bold">
              The Universal Collective
            </h2>
          </div>
        </a>
        <div className="md:hidden">
          <button
            type="button"
            className="p-8 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
            onClick={() => setNavbar(!navbar)}
          >
            {navbar ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <div className="z-20">
        <div
          className={`flex-1 justify-self-center p-3 md:block md:pb-0 md:mt-0 ${
            navbar ? 'block' : 'hidden'
          }`}
        >
          <ul className="justify-center  text-white font-bold pl-8 space-y-8 md:flex md:space-x-6 md:space-y-0 z-20">
            <li className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25 ">
              <Link to="/">Market</Link>
            </li>
            <li
              className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25"
              onClick={closeNavbar}
            >
              <Link to="/Collections">Collections</Link>
            </li>
            <li
              className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25"
              onClick={closeNavbar}
            >
              <Link to="/About">About</Link>
            </li>
            {/* <Link
              to="/wallet"
              className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25"
            >
              Wallet
            </Link>
            <Link
              to="/swap"
              className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25"
            >
              Swap
            </Link> */}
            <li
              className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25"
              onClick={closeNavbar}
            >
              <Link to="/Learn">Learn</Link>
            </li>
            <li
              className="cursor-pointer rounded-sm pt-2 hover:text-blue-500 hover:bg-opacity-25"
              onClick={closeNavbar}
            >
              {admin ? <Link to="/admin">Admin</Link> : null}
            </li>
            {!connectedAccount ? (
              <button
                className="shadow-md shadow-blue-700 bg-blue-500 hover:bg-blue-700 md:text-sm sm:text-base p-2 rounded-md"
                onClick={connectWallet}
              >
                Connect Wallet
              </button>
            ) : (
              <button className="shadow-md shadow-blue-700 bg-blue-500 hover:bg-blue-700 md:text-sm sm:text-base p-2 rounded-md">
                {truncate(connectedAccount, 4, 4, 11)}
              </button>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
