import { useState } from 'react';
import { toast } from 'react-toastify';
import { changeRoyalty } from '../services/blockchain';

// A component that represents a royalty field
const RoyaltyField = ({ currPct }) => {
  // State variable to store the royalty value
  const [royalty, setRoyalty] = useState('');

  // Function to handle the change in royalty price
  const handlePriceChange = async (e) => {
    e.preventDefault();

    // Display a toast while the royalty change is being processed
    await toast.promise(
      new Promise(async (resolve, reject) => {
        // Call the changeRoyalty function from the blockchain service
        await changeRoyalty(royalty)
          .then(() => {
            // Reset the royalty value and resolve the promise
            setRoyalty('');
            resolve();
          })
          .catch((error) => {
            // Alert and reject the promise if an error occurs
            alert(JSON.stringify(error));
            reject(error);
          });
      }),
      {
        //message display on change
        pending: 'Updating...',
        success: 'Royalty percent changed 👌',
        error: 'Encountered error 🤯',
      }
    );
  };

  return (
    <div className="flex justify-between items-center py-2 px-6 gap-4 w-full">
      <form
        onSubmit={handlePriceChange}
        className="flex flex-col sm:flex-row justify-between py-2 space-x-0
          sm:space-x-2 space-y-2 sm:space-y-0 w-full"
      >
        <input
          className="block flex-1 mb-4 sm:mb-0 sm:w-2/3 text-sm
            text-slate-500 bg-transparent border-gray-700
            focus:outline-none focus:border-blue-200 focus:ring-0 p-2 rounded-lg"
          type="number"
          min={0.01}
          max={100}
          step={0.01}
          name="royalty"
          placeholder={`Current Percent: ${currPct}%`}
          value={royalty}
          onChange={(e) => setRoyalty(e.target.value)}
          required
        />
        <button
          typeof="submit"
          className="border border-blue-500 text-blue-500 p-2 px-6 rounded-full shadow-md
            shadow-blue-300 transform transition-transform duration-30 w-fit"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default RoyaltyField;
