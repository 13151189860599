import React from 'react';
import city from '../assets/images/citymodernart.png';
import nft from '../assets/images/NFT.png';
//standard component to display information regarding NFT's
const WhatIsAnNFT = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#intro">
                Introduction
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#fnf">
                Fungible vs Non-Funible
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#usedfor">
                What are NFT's used for?{' '}
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#howtobuysell">
                How are NFT's bought and sold?{' '}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="intro" className="mb-8">
          <h1 className="text-3xl font-extrabold mb-4">What is and NFT?</h1>
          <h2 className="text-2xl font-bold mb-4">Introduction</h2>
          <p>
            An NFT, short for Non-Fungible Token, is a type of digital asset
            that represents ownership or proof of authenticity of a unique item
            or piece of content. Unlike cryptocurrencies such as Bitcoin or
            Ethereum, which are fungible and can be exchanged on a one-to-one
            basis, NFTs are distinct and cannot be exchanged on an equal basis.
          </p>
          <p className="pt-4">
            {' '}
            NFTs are typically built on blockchain technology, which provides a
            decentralized and transparent system for recording ownership and
            transaction history. Each NFT contains unique metadata that
            distinguishes it from other tokens, such as artwork, music, videos,
            virtual real estate, collectibles, or even tweets. The metadata may
            include information like the creator, the date of creation, a
            description, and sometimes additional content or media.
          </p>
          <p className="pt-4">
            One of the key features of NFTs is their ability to establish
            verifiable ownership and provenance, allowing creators and
            collectors to prove the authenticity and rarity of a digital item.
            NFTs have gained popularity in the art world, where artists can sell
            their digital creations directly to buyers, eliminating the need for
            intermediaries like galleries or auction houses.{' '}
          </p>
          <p className="pt-4">
            {' '}
            NFTs can be bought, sold, and traded on various online platforms
            that support blockchain technology. Transactions involving NFTs are
            recorded on the blockchain, providing a transparent and permanent
            record of ownership transfers. The value of an NFT is determined by
            factors such as its uniqueness, desirability, scarcity, and the
            demand for the associated digital content.
          </p>
        </section>
        <img
          src={city}
          alt="Image 1"
          className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
        />
        <section id="fnf" className="pt-8 mb-8">
          <h2 className="text-2xl font-bold mb-4">
            Fungible vs <span className="text-blue-400">Non-Fungible</span>{' '}
          </h2>
          <p>
            An NFT, short for Non-Fungible Token, is a type of digital asset
            that represents ownership or proof of authenticity of a unique item
            or piece of content. Unlike cryptocurrencies such as Bitcoin or
            Ethereum, which are fungible and can be exchanged on a one-to-one
            basis, NFTs are distinct and cannot be exchanged on an equal basis.
          </p>
          <p className="pt-4">
            {' '}
            NFTs are typically built on blockchain technology, which provides a
            decentralized and transparent system for recording ownership and
            transaction history. Each NFT contains unique metadata that
            distinguishes it from other tokens, such as artwork, music, videos,
            virtual real estate, collectibles, or even tweets. The metadata may
            include information like the creator, the date of creation, a
            description, and sometimes additional content or media.
          </p>
          <p className="pt-4">
            One of the key features of NFTs is their ability to establish
            verifiable ownership and provenance, allowing creators and
            collectors to prove the authenticity and rarity of a digital item.
            NFTs have gained popularity in the art world, where artists can sell
            their digital creations directly to buyers, eliminating the need for
            intermediaries like galleries or auction houses.{' '}
          </p>
          <p className="pt-4">
            {' '}
            NFTs can be bought, sold, and traded on various online platforms
            that support blockchain technology. Transactions involving NFTs are
            recorded on the blockchain, providing a transparent and permanent
            record of ownership transfers. The value of an NFT is determined by
            factors such as its uniqueness, desirability, scarcity, and the
            demand for the associated digital content.
          </p>
        </section>
        <div className="md:mx-30 lg:mx-56">
          <img
            src={nft}
            alt="Image 1"
            className="w-3/5 mx-auto items-center justify-center "
          />
        </div>
        <section id="usedfor" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            What are <span className="text-blue-400"> NFT's used for?</span>
          </h2>
          <p>
            NFTs are digital assets used to represent ownership or authenticity
            of unique items. They are commonly used for digital art,
            collectibles, and virtual assets in gaming. NFTs enable artists to
            sell and protect their digital creations, and users can trade or buy
            virtual items securely. They have also been used for virtual real
            estate, intellectual property, tokenizing real-world assets,
            fundraising, and investments. The NFT market is evolving, with new
            use cases continually emerging.
          </p>
        </section>
        <section id="howtobuysell" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            How are NFT's{' '}
            <span className="text-blue-400"> bought and sold?</span>{' '}
          </h2>
          <p>
            Numerous online markets have emerged in response to the rising
            demand for NFTs to be purchased and sold. Every marketplace is
            unique; some specialise in a single blockchain, some are controlled
            and exclusive, and some concentrate on particular NFT types. The
            largest and most comprehensive NFT marketplace is OpenSea, which
            supports various blockchains and holds millions of NFTs.{' '}
          </p>
        </section>
        {/* <section id="images" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Images</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <img
              src="/path/to/image2.jpg"
              alt="Image 1"
              className="w-full h-auto"
            />
            <img
              src="/path/to/image2.jpg"
              alt="Image 2"
              className="w-full h-auto"
            />
            <img
              src="/path/to/image3.jpg"
              alt="Image 3"
              className="w-full h-auto"
            />
          </div>
        </section>
        <section id="interactive">
          <h2 className="text-2xl font-bold mb-4">Interactive Section</h2>
        </section> */}
      </div>
    </div>
  );
};

export default WhatIsAnNFT;
