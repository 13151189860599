import { BsArrowRightShort } from 'react-icons/bs';
import polymatic from '../assets/images/polymatic.png';
import { setGlobalState } from '../store';

const Hero = () => {
  return (
    <div
    
      className="flex flex-col items-start md:flex-row
    space-y-4 sm:space-y-0"
    >
      
      <Banner />
      <Bidder />
    </div>
  );
};

const Banner = () => {
  return (
    <div className="flex flex-col md:flex-row w-full justify-between items-center mx-auto z-20">
      <div className="text-white">
        <h1 className="font-semibold text-5xl py-1">Create, Collect</h1>
        <h1 className="font-semibold text-4xl mb-4 py-2">
          and Sell<span className="text-blue-500 px-1">NFTs</span>.
        </h1>
        <p className="md:w-3/5 font-light mb-11">
          Artists choose a charity to receive royalties from resales. Users can
          buy and sell NFTs on the secondary market, and transaction history is
          transparent. The platform promotes artist profiles, artwork galleries,
          and community engagement. Compliance with legal and regulatory
          requirements is ensured. The marketplace aims to support artists and
          charitable causes simultaneously.
        </p>
        {/* Secondary title */}
        <div>
          <button
            className="text-sm my-4 p-2 bg-blue-500 rounded-sm w-auto flex justify-center items-center shadow-md shadow-blue-700 hover:bg-blue-700 "
            onClick={() => setGlobalState('boxModal', 'scale-100')}
          >
            Create NFT
            <BsArrowRightShort size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

const Bidder = () => {
  return (
    <div className="flex items-center justify-center group h-96 w-80 [perspective:1000px]">
      <div className="relative h-full w-full rounded-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] [backface-visibility:hidden]">
        <div className="absolute inset-0">
          <img
            className="object-cover px-8  rounded-xl "
            src={polymatic}
            alt="kiwiWeb"
          />
          <h1 className="text-white text-center font-semi bold font-xl pt-4">
            Powered by Polygon - Matic
          </h1>
        </div>
        <div className="relative h-full w-full rounded-xl transition-all duration-500  [transform:rotateY(180deg)] [backface-visibility:hidden]">
          <div className="absolute inset-0">
            <img
              className="object-cover px-8  rounded-xl "
              src={polymatic}
              alt="kiwiWeb"
            />
            <h1 className="text-white text-center font-semi bold font-xl pt-4">
              Powered by Polygon - Matic
            </h1>
          </div>
        </div>
      </div>
      <div className="blob"></div>
      <div className="blob1"></div>
    </div>
  );
};

export default Hero;
