import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ChangePrice from './components/ChangePrice';
import Footer from './components/Footer';
import Header from './components/Header';
import OfferItem from './components/OfferItem';
import About from './views/About';
import Learn from './views/Learn';
import PlaceBid from './components/PlaceBid';
import { isWalletConnected, loadAdmin } from './services/blockchain';
import { useGlobalState } from './store';
import Collections from './views/Collections';
import Home from './views/Home';
import OnchainNFT from './views/OnchainNFT';
import Partners from './views/Partners';
import Policy from './views/Policy';
import OffchainNFT from './views/OffchainNFT';
import TopUp from './views/TopUp';
import Success from './views/Success';
import Canceled from './views/Canceled';
import Swap from './views/Swap';
import Admin from './views/Admin';
import PrivateAdminRoutes from './utils/PrivateAdminRoutes';
import WhatIsAnNFT from './views/WhatIsAnNFT';
import HowToBuyAnNFT from './views/HowToBuyAnNFT';
import HowToCreateAnNFT from './views/HowToCreateAnNFT';
import WhatIsMinting from './views/WhatIsMinting';
import HowToSellAnNFTOnUC from './views/HowToSellAnNFTOnUC';
import WhatIsTheBlockchain from './views/WhatIsTheBlockchain';
import WhatAreGasFees from './views/WhatAreGasFees';
import WhatIsPolygon from './views/WhatIsPolygon';
import WhatIsWeb3 from './views/WhatIsWeb3';
import WhatIsCryptoCurrency from './views/WhatIsCryptoCurrency';
import WhatIsACryptoWallet from './views/WhatIsACryptoWallet';
import HowToSetUpYourWallet from './views/HowToSetUpYourWallet';

const App = () => {
  const [auction] = useGlobalState('auction');

  useEffect(async () => {
    await isWalletConnected();
    await loadAdmin();
  }, []);

  return typeof window === 'undefined' ? null : (
    <div
      className="min-h-screen bg-gradient-to-t from-gray-800 bg-repeat
    via-[#000000] to-gray-900 bg-center subpixel-antialiased"
    >
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/nft/onchain/:id" element={<OnchainNFT />} />
        <Route path="/nft/offchain/:id" element={<OffchainNFT />} />
        <Route path="/About" element={<About />} />
        <Route path="/Learn" element={<Learn />} />
        <Route path="/WhatIsAnNFT" element={<WhatIsAnNFT />} />
        <Route path="/HowToBuyAnNFT" element={<HowToBuyAnNFT />} />
        <Route
          path="/HowToSetUpYourWallet"
          element={<HowToSetUpYourWallet />}
        />
        <Route path="/HowToCreateAnNFT" element={<HowToCreateAnNFT />} />
        <Route path="/WhatIsMinting" element={<WhatIsMinting />} />
        <Route path="/HowToSellAnNFTOnUC" element={<HowToSellAnNFTOnUC />} />
        <Route path="/WhatIsTheBlockchain" element={<WhatIsTheBlockchain />} />
        <Route path="/WhatAreGasFees" element={<WhatAreGasFees />} />
        <Route path="/WhatIsPolygon" element={<WhatIsPolygon />} />
        <Route path="/WhatIsWeb3" element={<WhatIsWeb3 />} />
        <Route
          path="/WhatIsCryptoCurrency"
          element={<WhatIsCryptoCurrency />}
        />
        <Route path="/WhatIsACryptoWallet" element={<WhatIsACryptoWallet />} />
        <Route element={<PrivateAdminRoutes />}>
          <Route path="/admin" element={<Admin />} />
        </Route>
        <Route path="/Partners" element={<Partners />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/wallet" element={<TopUp />} />
        <Route path="/swap" element={<Swap />} />
        <Route path="/success" element={<Success />} />
        <Route path="/canceled" element={<Canceled />} />
      </Routes>
      <Footer />

      {auction ? (
        <>
          <ChangePrice />
          <PlaceBid />
          <OfferItem />
        </>
      ) : null}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable
        theme="dark"
      />
    </div>
  );
};

export default App;
