import axios from 'axios'
const BASE_URI = process.env.REACT_APP_BASE_URL

const getCollection = async (owner) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(BASE_URI + '/nfts/collection/' + owner)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const getNFTs = async () => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(BASE_URI + '/nfts')
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const getNFT = async (id) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(BASE_URI + '/nfts/' + id)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const processNFT = async (formData) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(BASE_URI + '/process', formData)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const mintNFT = async (formData) => {
  return new Promise(async (resolve, reject) => {
    const authed = await authUser(formData)
    await axios
      .post(BASE_URI + '/mint', formData, {
        headers: {
          Authorization: `${authed.token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const deleteNFT = async (formData) => {
  return new Promise(async (resolve, reject) => {
    const authed = await authUser(formData)
    await axios
      .post(BASE_URI + '/nfts/delete', formData, {
        headers: {
          Authorization: `${authed.token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const getBalance = async (formData) => {
  return new Promise(async (resolve, reject) => {
    const authed = await authUser(formData)

    await axios
      .post(BASE_URI + '/balance', formData, {
        headers: {
          Authorization: `${authed.token}`,
          Accept: '*/*',
        },
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response.data))
  })
}

const authUser = async (formData) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(BASE_URI + '/authenticate', formData)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response.data))
  })
}

const checkoutStripe = async (formData) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(BASE_URI + '/checkout', formData)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const swapToMatic = async (formData) => {
  return new Promise(async (resolve, reject) => {
    const authed = await authUser(formData)
    await axios
      .post(BASE_URI + '/swap', formData, {
        headers: {
          Authorization: `${authed.token}`,
          Accept: '*/*',
        },
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const getExchangeRate = (currencySymbol) => {
  const url = `https://api.coinbase.com/v2/exchange-rates?currency=${currencySymbol}`
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error(`Request failed with status code ${response.status}`)
      })
      .then((data) => resolve(data.data.rates))
      .catch((error) => reject(error))
  })
}

export {
  processNFT,
  mintNFT,
  getNFTs,
  getNFT,
  deleteNFT,
  getCollection,
  authUser,
  checkoutStripe,
  swapToMatic,
  getBalance,
  getExchangeRate,
}
