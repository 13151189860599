import React from 'react';
import block from '../assets/images/blockchain.png';
import sm from '../assets/images/scontract.png';
//standard component to display information regarding blockchain
const WhatIsTheBlockchain = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#101">
                Introduction
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#blockchain">
                What is a blockchain?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#traditional">
                How is a blockchain different from a traditional database?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#Types">
                What are the types of blockchains?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#mechanisms">
                What are consensus mechanisms?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#Gas">
                What are Gas fees?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#used">
                How is the blockchain regularly used?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#future">
                Blockchain's future potential uses
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="101" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-4">Blockchain 101</h1>
          <h2 className="text-2xl font-bold mb-2">Introduction</h2>
          <p>
            A blockchain is a decentralised ledger that is distributed digitally
            and stores information and transactions. There are various
            blockchains kinds. The blockchains of Ethereum, Polygon, Klaytn,
            Arbitrum, Optimism, Avalanche, and BNB Chain are all compatible with
            Universal Collective.
          </p>
        </section>
        <section id="blockchain" className="mb-10">
          <div className="flex">
            <div className="w-2/3">
              <h2 className="text-2xl font-bold mb-2">
                What is <span className="text-blue-500">blockchain?</span>{' '}
              </h2>
              <p>
                A decentralised record known as a blockchain takes its name from
                the way that data is stored there. A group of transactional data
                becomes a "block" whenever it reaches a certain size. Every
                transaction on a blockchain is verified here before being saved
                permanently. A blockchain's "chain" component consists of a set
                of contiguous blocks that are connected to create an immutable
                ledger.
              </p>
            </div>
            <div className="mx-auto w-60">
              <img
                src={block}
                alt="Image by macrovector on Freepik"
                className=""
              />
            </div>
          </div>
        </section>
        <section id="traditional" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How is a blockchain different from a{' '}
            <span className="text-blue-500">traditional database?</span>
          </h2>
          <p>
            A typical database is different from a blockchain in two ways: how
            it works and who oversees it. A traditional database is normally
            owned and controlled by a single body, whereas a blockchain is
            decentralised and runs without the oversight of a company or
            financial institution. It permits transactions that are both
            trustless and permissionless.
          </p>
          <p className="pt-2">
            Here are the top five distinguishing features of blockchain
            technology:
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">Decentralization</h3>
            <p>
              No single institution (like a central bank or corporation) would
              own each blockchain or the underlying technology. Instead, many
              computers (referred to as "nodes") that verify transactions
              support each blockchain. Blockchain security is ensured through
              consensus procedures (more on this below).
            </p>
            <h3 className="pt-4 font-bold mb-2">
              Validated by multiple independent nodes
            </h3>
            <p>
              A consensus process verifies any digital assets or data kept on
              the blockchain using several independent nodes (or computers).
              Consensus techniques often fall into one of two categories:
              Proof-of-Stake or Proof-of-Work. A new block is added to the
              blockchain once a predetermined number of these transactions has
              been verified and recorded.
            </p>
            <h3 className="pt-4 font-bold mb-2">Public and transparent</h3>
            <p>
              The decentralised nature of the blockchain enables the ledger to
              be open and transparent while maintaining anonymity. Every
              transaction is permanently recorded and available to the public.
            </p>
            <p className="pt-4">
              In 2016, Fabricio Santos compared the blockchain to a bank vault
              full of rows of glass safe deposit boxes that allow everyone to
              view the contents without having access to them. When someone
              opens a new safe deposit box, they are given a key that is
              specific to that box, but producing a copy of the key does not
              duplicate the contents of the box; it merely grants access, he
              explained.
            </p>
            <h3 className="pt-4 font-bold mb-2">Immutable</h3>
            <p>
              Once a block has been connected to a chain, it becomes immutable
              and cannot be modified. It is difficult for anyone to falsify the
              record since a block can only be changed if most independent nodes
              agree to verify the change.
            </p>
            <h3 className="pt-4 font-bold mb-2">
              Trustless and permissionless
            </h3>
            <p>
              The blockchain keeps track of the past, protects it, and
              participates in transactions impartially. It is "trustless"
              because you do not need to put your trust in another company or
              entity to transact, for this reason. Like this, the transactions
              are "permissionless" in the sense that they don't need the consent
              of a third party because they are carried out by a network of
              computers.
            </p>
          </div>
        </section>
        <section id="Types" className="mb-8">
          <h2 className="text-2xl font-bold mb-2">
            What are the{' '}
            <span className="text-blue-500">Types of blockchains?</span>
          </h2>
          <p>
            There are various blockchain varieties, and each has a different gas
            fee and scalability.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">Layer 1 blockchains</h3>
            <p>
              The primary or base level blockchains are Layer 1 (sometimes
              referred to as "L1") blockchains. This indicates that they
              independently execute and validate transactions. These Layer 1
              chains include Ethereum, Solana, and Avalanche. All three of these
              L1 chains have their associated coins and can operate
              independently of other systems.
            </p>
            <h3 className="pt-4 font-bold mb-2">Layer 2 blockchains</h3>
            <p>
              Blockchains that are Layer 2 (or "L2") serve as scaling solutions
              for Layer 1s. A summary of the finished operations on the Layer 1
              chain is then stored by Layer 2 chains after transactions have
              been processed on their chain. Since their transactions start
              off-chain and are later added to the Layer 1 or base chain,
              Optimism and Arbitrum are regarded as Layer 2 "rollups". As a
              result, the demands placed on the blockchain are greatly reduced,
              enhancing scalability, and cutting gas costs.
            </p>
            <h3 className="pt-4 font-bold mb-2">Sidechains</h3>
            <p>
              A "sidechain" is the last form of a chain, which is a distinct,
              independent blockchain connected to the Layer 1 chain by a two-way
              bridge. In essence, sidechains like Polygon are self-contained
              blockchains in charge of their security. They are created to
              handle transactions quickly. Their capacity to move digital assets
              to the main blockchain is what links them to it. Users of Polygon,
              an Ethereum-compatible chain, have a similar user experience to
              that of Ethereum with the possibility of lower gas costs and
              quicker transaction times.
            </p>
          </div>
        </section>
        <section id="mechanisms" className="mb-8">
          <h2 className="text-2xl font-bold mb-2">
            {' '}
            What are{' '}
            <span className="text-blue-500">Consensus mechanisms?</span>
          </h2>
          <p>
            Blockchain transactions must be verified by network nodes, as
            previously mentioned. Consensus methods serve as how transactions
            are verified; they are how all nodes concur that a transaction
            should be permanently logged (hence the name "consensus"). Consensus
            mechanisms primarily fall under two categories: Proof of Stake and
            Proof of Work.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">The Proof-of-Stake method</h3>
            <p>
              Validators are used in Proof-of-Stake blockchains to validate
              transactions. A validator is chosen at random to be a block
              proposer when a new transaction may be added to the blockchain.
              Then, this validator will produce a new block and distribute it to
              the other network nodes. A committee of validators is selected for
              each new slot, and their votes determine whether the block is
              genuine. Ethereum is a prime example of a blockchain that makes
              use of Proof-of-Stake.
            </p>
            <h3 className="pt-4 font-bold mb-2">The Proof-of-Work method</h3>
            <p>
              Miners are used by blockchains that employ the Proof-of-Work
              algorithm to validate transactions. Blockchains with proof-of-work
              leverage consensus and extremely high computational output (the
              "work") as barriers to preventing malicious parties from adding
              erroneous transactions to the network. A group of miners (or,
              rather, their computers) will compete to solve a challenging
              mathematical equation whenever a new transaction is potentially
              going to be added to the blockchain. Several additional miners
              will check that the solution provided by the first miner is
              accurate. The transaction will be added to the blockchain, and the
              quickest miner will be paid for the energy spent to complete the
              calculation if everyone accepts that the output is correct.
            </p>
            <p className="pt-2">
              With Proof-of-Work, the entry barrier for a bad actor is quite
              high since many miners must concur on a transaction's authenticity
              and because it takes a lot of computing work and energy to
              validate a transaction. For instance, there is a limited quantity
              of Bitcoin in circulation. Therefore, an increasingly difficult
              puzzle must be solved to mine each additional coin. Additionally,
              this raises the cost of transactions.
            </p>
            <p className="pt-2">
              The operators receive the gas fees since both procedures are
              intricate, time-consuming, and ultimately secure the security of
              the blockchain.
            </p>
          </div>
        </section>
        <section id="Gas" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            {' '}
            What are <span className="text-blue-500">Gas fees?</span>
          </h2>
          <p>
            "Gas fee" in web3 refers to the sum required to carry out a
            transaction on the blockchain. The node operators who keep the
            blockchain running are compensated by these payments. This
            verification makes sure the blockchain has an unchangeable,
            permanent record. Different gas costs apply to each of the Universal
            Collective-compatible blockchains (Ethereum, Polygon, Arbitrum,
            Optimism, and Klaytn). Depending on how each chain validates
            transactions, these fees vary.
          </p>
        </section>
        <section id="used" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            How is the blockchain{' '}
            <span className="text-blue-500">regularly used?</span>{' '}
          </h2>
          <p>
            Transferring money, buying things, storing things, and tracking
            things as they move from point A to point B are four common use
            cases for blockchains. These application cases are made available by
            more recent blockchains like Polygon and Ethereum. Bitcoin is
            designed as a ledger and lacks smart contract functionality.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">Cryptocurrency</h3>
            <p>
              A blockchain is the foundation of cryptocurrency, which is a
              virtual currency. On the Ethereum blockchain, for instance, Ether
              (ETH) is used. All digital currencies that are accessible through
              your crypto wallet are collectively referred to as cryptocurrency.
              You can buy, sell, and access your cryptocurrencies and (in many
              circumstances) your NFTs with the use of your wallet.
            </p>
            <h3 className="pt-4 font-bold mb-2">DeFi</h3>
            <p>
              The phrase "decentralised finance," frequently abbreviated as
              "DeFi," refers to financial services and exchanges that utilise
              blockchain technology. Earning interest, borrowing, lending, and
              trading are examples of common services. Trustless,
              permissionless, and quick transactions are made possible by DeFi.
            </p>
            <h3 className="pt-4 font-bold mb-2">Smart contracts</h3>
            <p>
              Automated computer programmes that enforce their coded rules are
              known as smart contracts. Smart contracts provide the "if THIS,
              then THAT" logic that drives NFTs and dApps. The Seaport smart
              contract protocol underpins the purchasing and selling of NFTs
              through Universal Collective.
            </p>
            <div className="flex items-center justify-center">
              <img src={sm} alt="smart contract" className="py-10" />
            </div>
            <h3 className="pt-4 font-bold mb-2">NFTs</h3>
            <p>
              Non-fungible tokens (NFTs) are exclusive digital assets whose
              ownership is controlled by a blockchain. They're exchanged for and
              against cryptocurrencies. Digital artwork, collectibles, virtual
              reality products, cryptographic domain names, ownership records
              for tangible objects, and more are examples of NFTs.
            </p>
            <h3 className="pt-4 font-bold mb-2">Supply chain</h3>
            <p>
              Blockchain-stored supply chain data enables businesses to track
              products ranging from food to medications. Blockchain technology
              is used by businesses in their supply chains to track hazardous
              food for recalls and discover fake pharmaceuticals. For instance,
              IBM Food Trust has concentrated on using blockchain to support
              supply chains.
            </p>
          </div>
        </section>
        <section id="future" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            Blockchain's future{' '}
            <span className="text-blue-500">potential uses?</span>
          </h2>
          <p>
            The development of many additional applications for blockchain has
            taken place after it took almost two decades to go from theory to
            practice. The use of blockchain to store and protect medical records
            or to exchange medical research is one of technology's most
            anticipated future applications. Many believe that blockchain will
            one day upend other industries, including those of real estate,
            insurance, and transportation.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WhatIsTheBlockchain;
