import logo from '../assets/images/Smallwhite.png';
import { FaTwitter, FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

// Footer component
function Footer() {
  return (
    <div className="py-5 m-auto sm:px-6 lg:px-8 justify-between items-center">
      <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-blue-500 to-transparent opacity-25 dark:opacity-100" />
      <div className=" m-auto w-1/2 flex flex-wrap justify-between items-center">
        <div className="w-full flex items-center justify-center sm:w-auto sm:mb-0 text-white mb-4">
          <img src={logo} alt="logo" />
        </div>
        <div className="w-full text-center sm:w-auto mb-6 py sm:text-left">
          <h3 className="text-white font-bold mb-2">Resources</h3>
          <ul className="text-gray-400">
            <li className="mb-1 cursor-pointer hover:text-blue-500">
              <a href="/Learn">Learn</a>
            </li>
            <li className="mb-1 hover:text-blue-500">
              <a href="/Partners">Partners</a>
            </li>
            <li className="mb-1 hover:text-blue-500">
              <a href="/Policy">Policy</a>
            </li>
          </ul>
        </div>
        <div className="w-full text-center sm:w-auto mb-6 sm:text-left ">
          <h3 className="text-white font-bold mb-2">Company</h3>
          <ul className="text-gray-400">
            <li className="mb-1 hover:text-blue-500">
              <a href="/About">About</a>
            </li>
            <li className="mb-1 hover:text-blue-500">
              <a href="/About">Charities</a>
            </li>
            <li className="mb-1 hover:text-blue-500">
              <a href="/About">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="mt-8 w-full flex items-center justify-center">
          <a href="#" className="text-white hover:text-blue-500">
            <span className="sr-only">Twitter</span>
            <FaTwitter className="h-6 w-6" />
          </a>
          <a href="#" className="ml-6 text-white hover:text-blue-500">
            <span className="sr-only">Instagram</span>
            <FaInstagram className="h-6 w-6" />
          </a>
          <a href="#" className="ml-6 text-white hover:text-blue-500">
            <span className="sr-only">Facebook</span>
            <FaFacebook className="h-6 w-6" />
          </a>
          <a href="#" className="ml-6 text-white hover:text-blue-500">
            <span className="sr-only">Facebook</span>
            <FaLinkedin className="h-6 w-6" />
          </a>
        </div>
      </div>
      <p className=" text-white pt-8 text-center text-xs">
        &copy;2023 All rights reserved. Nxtech Solutions Ltd
      </p>
    </div>
  );
}

export default Footer;
