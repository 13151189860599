import React, { useEffect } from 'react';
import { useState } from 'react';
import { getBalance, getExchangeRate, swapToMatic } from '../services/api';
import { useGlobalState } from '../store';
import { toast } from 'react-toastify';
import { getCoinBalance } from '../services/blockchain';

// Global state variables
const Swap = () => {
  const [connectedAccount] = useGlobalState('connectedAccount');
  const [balance, setBalance] = useState('');
  const [coinBalanace, setCoinBalance] = useState('');
  const [matic, setMatic] = useState('');
  const [maticRates, setMaticRate] = useState('');
  const [nzdRates, setNzdRate] = useState('');
  const [nzd, setNzd] = useState('');
  const [swapped, setSwapped] = useState(false);

  useEffect(async () => {
    // Fetch exchange rates for MATIC and NZD
    const maticConversionRates = await getExchangeRate('MATIC');
    const nzdConversionRates = await getExchangeRate('NZD');

    setMaticRate(maticConversionRates);
    setNzdRate(nzdConversionRates);

    // Fetch coin balance for the connected account
    const balance = await getCoinBalance(connectedAccount);
    setCoinBalance(balance);

    // Extract the balance of the connected account
    await extractBalance(connectedAccount);
  }, [connectedAccount, swapped]);

  const extractBalance = async (owner) => {
    // Fetch balance for the specified owner account
    const formData = new FormData();
    formData.append('owner', owner);
    await getBalance(formData).then((user) => setBalance(user.balance));
  };
  const handleMaticConvertion = async (value) => {
    if (!value) {
      // Reset values if the input is empty
      setNzd('');
      setMatic('');
      return;
    }
    // Calculate MATIC value based on the conversion rate
    const result = maticRates['NZD'] * value;
    setMatic(value);
    setNzd(result.toFixed(2));
  };

  const handleNzdConvertion = async (value) => {
    // Reset values if the input is empty
    if (!value) {
      setMatic('');
      setNzd('');
      return;
    }
    // Calculate NZD value based on the conversion rate
    const result = nzdRates['MATIC'] * value;
    setMatic(result.toFixed(2));
    setNzd(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!connectedAccount) return toast.warning('Connect account first');
    if (Number(nzd) > Number(balance))
      return toast.warning('Insufficient Fund');

    // Submit the swap request
    const formData = new FormData();
    formData.append('amount', nzd);
    formData.append('owner', connectedAccount);

    await toast.promise(
      new Promise(async (resolve, reject) => {
        // Perform the swap to MATIC
        await swapToMatic(formData)
          .then((result) => {
            console.log(result);
            setMatic('');
            setNzd('');
            setSwapped(!swapped);
            resolve(result);
          })
          .catch((error) => reject(error));
      }),
      {
        pending: 'Initiating swapping...',
        success: 'Swapping completed...',
        error: 'Encoutered an error',
      }
    );
  };

  return (
    <div
      className=" py-16 px-4 sm:px-6 lg:px-8 lg:py-24 space-y-4
    flex flex-col justify-center items-center sm:w-4/5 md:3/5 lg:w-2/5 mx-auto text-white"
    >
      <h1 className="text-3xl font-bold ">Account Information</h1>

      <div className="flex justify-start items-center space-x-2">
        <h2 className="text-xl font-semibold">NZ$ Balance:</h2>
        <p className="text-gray-700">{balance}</p>
      </div>
      <div className="flex justify-start items-center space-x-2">
        <h2 className="text-xl font-semibold">MATIC Balance:</h2>
        <p className="text-gray-700">{Number(coinBalanace).toFixed(2)}</p>
      </div>

      <form onSubmit={handleSubmit} className="w-full space-y-4">
        <input
          type="text"
          name="matic"
          value={matic}
          onChange={(e) => handleMaticConvertion(e.target.value)}
          className=" bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl
          rounded w-full py-2 px-3 text"
          placeholder="Matic Amount"
          required
        />
        <input
          type="text"
          name="nzd"
          value={nzd}
          onChange={(e) => handleNzdConvertion(e.target.value)}
          className=" bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl
          rounded w-full py-2 px-3 text"
          placeholder="NZD Amount"
          required
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white
          font-bold py-2 px-4 rounded focus:outline-none
          focus:shadow-outline w-full"
        >
          Swap
        </button>
      </form>
    </div>
  );
};

export default Swap;
