import React from 'react';
import cryptocur from '../assets/images/cryptocur.png';
//standard component to display information regarding  crypto currency
const WhatIsCryptoCurrency = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#intro">
                Introduction
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#blockchain">
                How are cryptocurrency and blockchain-related?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#advantages">
                The advantages of cryptocurrency
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#mind">
                Things to keep in mind when using cryptocurrency
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#people">
                How do people use cryptocurrency now?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#future">
                How might people use cryptocurrencies in the future?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#Types">
                Types of cryptocurrency
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#decentralization">
                What are the advantages of decentralization?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#access">
                How do I access my cryptocurrency?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#NFTs">
                How are cryptocurrency and NFTs related?
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="intro" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-4">
            What is cryptocurrency?
          </h1>
          <h2 className="text-2xl font-bold mb-2">Introduction</h2>
          <p>
            Cryptography is used to safeguard financial transactions, limit the
            creation of new units, and confirm the transfer of assets in the
            case of cryptocurrencies, which are digital or virtual forms of
            cash. It uses blockchain technology, a distributed ledger maintained
            by a network of computers, and is decentralised.
          </p>
          <p className="pt-4">
            Cryptocurrencies are often not issued or governed by any central
            authority, in contrast to traditional fiat currencies that are
            issued by governments. To protect transactions, regulate the
            creation of new units, and confirm the transfer of assets, they rely
            on cryptographic techniques.
          </p>
          <p className="pt-4">
            Bitcoin, which debuted in 2009, is the cryptocurrency that is most
            well-known and often utilised. But there are thousands of other
            cryptocurrencies out there right now, each with their special
            qualities and objectives. Ethereum, Ripple, Litecoin, and many more
            are examples.
          </p>
          <p className="pt-4">
            Compared to conventional financial systems, cryptocurrencies have
            several advantages, including the possibility for financial
            inclusion, faster and less expensive transactions, enhanced privacy,
            and security. They are becoming popular as a form of investment, a
            store of value, and a medium of exchange. Cryptocurrencies can be
            volatile, and their value can change considerably, so it's crucial
            to keep that in mind.
          </p>
          <p className="pt-4">
            In general, cryptocurrencies stand for a digital, decentralised form
            of money that has the power to completely alter how financial
            transactions are carried out and recorded.
          </p>
        </section>
        <section id="blockchain" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How are{' '}
            <span className="text-blue-500">
              cryptocurrency and blockchain's
            </span>{' '}
            related?
          </h2>
          <p>
            An analogy offered by Fabricio Santos in 2016 can be used to
            understand the connection between cryptocurrencies and blockchain.
            Santos compared the blockchain to a bank vault filled with
            transparent deposit boxes that everyone can view without being able
            to open them. He continued by saying that each new deposit box comes
            with a special key that can only be used for that box. However,
            copying the key just gives access; it does not replicate the
            contents of the box.
          </p>
          <p className="pt-4">
            Due to its unique features, blockchain technology stands out: all
            transactions are recorded forever on the blockchain and are
            accessible to anybody at any time. The history of transactions
            documented in the blockchain record may only be added to, not
            changed. The decentralised computer network that makes up the
            blockchain is what cryptocurrency uses to validate and verify
            ownership of digital assets.
          </p>
        </section>
        <section id="advantages" className="mb-10">
          <h2 className="text-2xl font-bold ">
            The <span className="text-blue-500">advantages</span> of
            cryptocurrency
          </h2>
          <div className="px-10">
            <h3 className="pt-4 font-bold mb-2">
              Secure, instant transactions
            </h3>
            <p>
              Secure and quick transactions are provided by cryptocurrencies as
              their primary feature. Cryptocurrencies are fundamentally about
              privacy since they eliminate the need to provide personal
              information to each party to a transaction. Your financial and
              personal information is safe when you use cryptocurrencies because
              it isn't given to unrelated parties who want access to it, like
              advertisements. Additionally, transactions with cryptocurrencies
              can happen almost instantly. Users no longer must endure the
              customary 3-4 day waiting period connected with transactions
              through conventional banks because they have quick access to their
              cryptocurrency wallets. This accelerated procedure improves the
              speed and ease of financial transactions.
            </p>
            <h3 className="pt-4 font-bold mb-2">
              The choice to have self-custody of your cryptocurrency
            </h3>
            <p>
              Whether it's a bank or another payment provider, the traditional
              banking system depends on you having faith in a single institution
              to hold and protect your money. But in addition to protecting your
              money, these centralised organisations might potentially use it
              for other purposes. Additionally, they have the authority to
              discontinue operations, alter existing regulations, or enact new
              ones that could affect your access to money or your capacity to
              use it.
            </p>
            <p className="pt-4">
              Cryptocurrency provides a solution by enabling decentralised
              finance, which gets rid of middlemen. Transactions in
              decentralised finance are "trustless" since you don't need to rely
              on anybody else for them to happen. Like earlier examples, as
              these transactions are carried out by a network of computers, they
              are "permissionless" in the sense that they do not require
              authorisation from a third party.
            </p>
            <p className="pt-4">
              Non-custodial wallets, which provide users self-custody of their
              cryptocurrency and helped DeFi gain traction, demand that users
              protect their own money. However, they also provide customers with
              the option to access their money whenever they choose.
            </p>
            <p className="pt-4">
              However, there are some middlemen in the world of
              cryptocurrencies. Systems mimicking traditional centralised
              banking are in use when buying and holding cryptocurrencies on
              centralised exchanges like Coinbase and Binance. These businesses
              serve as middlemen, providing extra convenience to clients who
              might not want to take on the full weight of maintaining a
              non-custodial wallet.
            </p>
            <h3 className="pt-4 font-bold mb-2">Anonymity and privacy</h3>
            <p>
              One of the most crucial characteristics of cryptocurrencies is
              frequently hailed as privacy. Cryptocurrency transactions are
              regarded as "pseudonymous." They don't offer complete anonymity,
              but neither do they demand the use of one's legal name or any
              other kind of identification. Instead, the address of your wallet
              is permanently linked to every transaction. Being able to access
              your cryptocurrency wallet privately and confidentially is a
              helpful feature, especially for people who belong to historically
              marginalised groups.
            </p>
          </div>
        </section>
        <section id="mind" className="mb-10">
          <h2 className="text-2xl font-bold">
            Things to keep in mind{' '}
            <span className="text-blue-500">when using cryptocurrency</span>
          </h2>
          <div className="px-10">
            <h3 className="pt-4 font-bold mb-2">New and constantly evolving</h3>
            <p>
              Even though blockchain technology was created in the 1990s, the
              first cryptocurrency, Bitcoin, was only first introduced in 2009.
              Due to the technology's immaturity, everyone is concurrently
              learning how to utilise it, which can level the playing field, but
              it also means that everyone is going through growing pains.
            </p>
            <h3 className="pt-4 font-bold mb-2">It's decentralized</h3>
            <p>
              Because blockchain technology is decentralised, there isn't a
              central bank you can go to for assistance with potential issues.
              If you select a non-custodial wallet versus a custodial wallet,
              this is especially true. In case you forgot, there are two
              different kinds of cryptocurrency wallets: custodial ("hosted")
              and non-custodial. A third-party company manages custodial wallets
              while a non-custodial wallet is not. Because non-custodial wallets
              allow you complete control and accountability, protecting your
              access key is even more crucial.
            </p>
          </div>
        </section>
        <section id="people" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How do people use{' '}
            <span className="text-blue-500">cryptocurrency now?</span>
          </h2>
          <p>
            Since the launch of Bitcoin in 2009, the variety and use of
            cryptocurrencies have expanded substantially. NFTs (non-fungible
            tokens) on universal collectives, tangible commodities, or in-person
            services can all now be purchased with cryptocurrency (McDonald's
            now accepts Bitcoin in some countries, and Google Cloud also accepts
            Bitcoin and Ethereum as payment for cloud infrastructure).
          </p>
        </section>
        <section id="future" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How might people use{' '}
            <span className="text-blue-500">
              cryptocurrencies in the future?
            </span>
          </h2>
          <p>
            A growing number of businesses (and sectors) are ready to start
            accepting cryptocurrency as a method of payment for any purchase.
            Additional industries are embracing blockchain technology, and
            potential future uses include everything from mortgages to medical
            records.
          </p>
        </section>
        <section id="Types" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            <span className="text-blue-500">Types</span> of cryptocurrency.
          </h2>
          <p>
            There are two different kinds of cryptocurrencies—coins and
            tokens—and there are some significant distinctions between them.
          </p>
          <div className="px-10">
            <h3 className="pt-4 font-bold">Coins</h3>
            <p>
              Coins may be more volatile in nature because they are linked to a
              certain blockchain and run only on that blockchain's decentralised
              system. (See below for further information on how this differs
              from stablecoins, which are pegged against a more definite
              entity.)
            </p>
            <div className="px-10">
              <h3 className="pt-4  font-bold">Bitcoin (BTC)</h3>
              <p>
                The original cryptocurrency, which now has the highest market
                capitalisation. used to purchase products from some retailers,
                auto lots, or PayPal.
              </p>
              <h3 className="pt-4 font-bold">Ethereum (ETH)</h3>
              <p>
                The open-source, decentralised blockchain is well recognised for
                its ability to support smart contracts, which helped make NFTs
                popular with users.
              </p>
              <h3 className="pt-4 font-bold ">Polygon (MATIC)</h3>
              <p>
                To create scalable dApps with low transaction costs, developers
                can use Polygon, an Ethereum sidechain that is EVM compatible.
              </p>
              <h3 className="pt-4 font-bold ">Solana (SOL)</h3>
              <p>
                Solana offers low gas costs, quick transactions, and the ability
                for developers to create dApps. The Proof-of-History approach is
                applied by Solana.
              </p>
              <h3 className="pt-4 font-bold">Avalanche (AVAX)</h3>
              <p>a green coin with a smart contract's emphasis.</p>
            </div>
            <h3 className="pt-4 font-bold">Tokens</h3>
            <p>
              Blockchains serve as the foundation for tokens. To lessen the
              volatility of the cryptocurrency, stablecoins, a common type of
              token, are anchored against an outside reference.
            </p>
            <div className="px-10">
              <h3 className="pt-4  font-bold">Tether (USDT)</h3>
              <p>
                the biggest stablecoin ranked third overall, which is backed by
                the US dollar.
              </p>
              <h3 className="pt-4 font-bold">USD Coin (USDC)</h3>
              <p>
                USDC is a natively existing digital US dollar that is accessible
                on several blockchains. USDC and USD can be exchanged at a 1:1
                ratio.
              </p>
              <h3 className="pt-4 font-bold ">Dai (DAI)</h3>
              <p>
                DAI, a stablecoin like USDC that runs on the Ethereum network,
                enables simple conversion to US dollars.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center py-8">
            <img src={cryptocur} alt="cryptoimage" />
          </div>
        </section>
        <section id="decentralization" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What are the{' '}
            <span className="text-blue-500">
              advantages of decentralization?
            </span>
          </h2>
          <p>
            Blockchain technology is not governed by a single institution (such
            as a central bank); rather, it is supported by many computer systems
            (referred to as "nodes"), which is what gives it its "decentralised"
            characteristic. As a result, those verifying or mining the
            blockchain are responsible for maintaining its security through a
            process known as the "consensus mechanism," in which all
            participating nodes must concur that a transaction is valid before
            it can be added to the blockchain. (Typically, a Proof-of-Work or a
            Proof-of-Stake system is used.)
          </p>
          <p className="pt-4">
            Even though Bitcoin is frequently referred to as secure, it's still
            crucial to follow best practices to protect your cryptocurrency.
            Never divulge your wallet's seed phrase, exercise caution whenever
            utilising your wallet, and be sure to carefully assess NFTs before
            purchasing. If something looks too good to be true, it probably is.
          </p>
        </section>
        <section id="access" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How do I{' '}
            <span className="text-blue-500">access my cryptocurrency?</span>
          </h2>
          <p>
            Using a crypto wallet, you have access to your cryptocurrency. One
            thing you should keep in mind is that your cryptocurrency doesn't
            reside in your wallet like cash does; rather, your wallet serves as
            a key to access your cryptocurrency, which is kept on the
            blockchain, much like a debit card would access cash from an ATM.
          </p>
          <p className="pt-4">
            You can purchase cryptocurrencies for your crypto wallet through an
            exchange like Binance or Coinbase, but many NFT-compatible wallets
            also allow you to add Bitcoin straight to your wallet using services
            like Moon Pay or Wyre. These are built inside the wallet and enable
            you to acquire cryptocurrencies without utilising an exchange by
            using a credit or debit card. During this process, you can be asked
            to prove your identity.
          </p>
        </section>
        <section id="NFTs" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How are{' '}
            <span className="text-blue-500">
              cryptocurrency and NFTs related?
            </span>
          </h2>
          <p>
            NFTs and cryptocurrencies both depend on blockchain technology. The
            main distinction is that while NFTs are "non-fungible" (unique and
            not interchangeable) tokens, cryptocurrencies are "fungible"
            (mutually interchangeable) tokens. One Bitcoin, for instance, is
            exactly equal to another Bitcoin, whereas every NFT is distinct.
          </p>
          <p className="pt-4">
            You'll need a cryptocurrency wallet in addition to cryptocurrency to
            buy an NFT. It's crucial to make sure your crypto wallet is
            compatible with both because it gives you access to both your
            cryptocurrencies and your NFTs. Although many NFTs on universal
            collective are accessible to be purchased with a credit or debit
            card, NFT transactions take place utilising cryptocurrencies. Here
            are further details on where to purchase an NFT.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WhatIsCryptoCurrency;
