import React from 'react';
import Pic1 from '../assets/images/CWPicture1.png';
import Pic2 from '../assets/images/CWPicture2.png';
//standard component to display information regarding crypto wallets
const WhatIsACryptoWallet = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#set">
                How to set up a Crypto Wallet
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#fund">
                How to fund your Crypto Wallet
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#protect">
                How to protect your Crypto Wallet
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#Connecting">
                Connecting your Crypto Wallet to universal Collective
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="intro" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-4">
            What is a Crypto Wallet?
          </h1>
          <h2 className="text-2xl font-bold mb-2">Introduction</h2>
          <p>
            A cryptocurrency wallet is a piece of software that makes it easier
            to buy, sell, and store cryptocurrencies as well as (in many cases)
            NFTs. You may send and receive stuff from it, it holds your items,
            and you want to keep it protected and safe. Think of it as your
            address on the blockchain. We'll go through the many kinds of
            cryptocurrency wallets in this article, along with how to put one
            up.
          </p>
        </section>
        <section id="set" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How to <span className="text-blue-500">set up a crypto wallet</span>
          </h2>
          <p>
            Although the procedure for setting up a cryptocurrency wallet varies
            from wallet to wallet, in this example, we'll go through the steps
            involved in setting up a Metamask wallet. Popular among NFTs is
            Metamask, a non-custodial software wallet.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold">
              1. Download the wallet browser extension
            </h3>
            <p className="pt-2 pb-8">
              Metamask has an iOS app, an Android app, and a web extension like
              many wallets do. Downloading the browser extension is all you need
              to get going.
            </p>
            <img
              src={Pic1}
              alt="Image 1"
              className="w-4/5 rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
            />
            <h3 className="pt-8 font-bold">
              2. Open the browser extension and follow the prompts
            </h3>
            <p className="pt-2">
              Once it has been installed, open the extension. You'll receive a
              prompt to begin. Pick the option to create a new wallet, then
              adhere to the instructions.
            </p>
            <h3 className="pt-4 font-bold">
              3. Create and store your seed phrase in a secure location
            </h3>
            <p className="pt-2">
              You'll be given your seed phrase throughout the setup procedure.
              Be cautious to adhere to recommended procedures for protecting
              your wallet. Keep your seed phrase in a secure location that
              nobody else can access. Never divulge your seed phrase to others.
            </p>
            <h3 className="pt-4 font-bold">
              4. Connect your crypto wallet to a universal collective
            </h3>
            <p className="pt-2">
              You are now prepared to link your wallet to the global collective
              after it has been configured.
            </p>
          </div>
        </section>
        <section id="fund" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How to{' '}
            <span className="text-blue-500">fund your crypto wallet</span>
          </h2>
          <p>
            You can purchase NFTs now that your wallet is configured! Although
            you can use universal-collective to purchase some NFTs with a credit
            or debit card, there may be situations where you'll need to have
            Bitcoin on hand.{' '}
          </p>
          <p className="pt-2">
            There are two primary ways to fund your cryptocurrency wallet,
            albeit each wallet is unique:
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold">
              Adding funds directly to your wallet
            </h3>
            <p className="pt-2">
              Numerous NFT-compatible wallets allow you to add Bitcoin straight
              to your wallet by utilising a platform like Wyre or Moonpay. These
              are built inside the wallet and enable you to acquire
              cryptocurrencies without utilising an exchange by using a credit
              or debit card. During this process, you can be asked to prove your
              identity.
            </p>
            <h3 className="pt-4 font-bold">
              Transferring cryptocurrency from a crypto exchange
            </h3>
            <p className="pt-2 pb-8">
              Purchasing cryptocurrency through an exchange like Kraken,
              Binance, or Coinbase provides an additional choice. Typically,
              when you purchase Bitcoin through an exchange, it is placed in a
              custodial wallet run by the exchange, the majority of which is
              incompatible with NFTs. Therefore, you must move the
              cryptocurrency from the custodial exchange wallet to your other
              wallet to fund the cryptocurrency wallet that you'll use for NFTs.
              To buy NFTs with ETH, for instance, you would need to transfer it
              from your custodial Binance wallet to your Metamask wallet.
            </p>
          </div>
          <img
            src={Pic2}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <p className="pt-5 w-2/5 mx-auto">
            The benefit of adopting this technique is that there aren't any
            maximums, unlike when you use a service like Moonpay or Wyre to
            deposit cryptocurrency directly into your wallet.
          </p>
        </section>
        <section id="protect" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How to{' '}
            <span className="text-blue-500">protect your crypto wallet </span>
          </h2>
          <p>
            You'll want to use some best practices for keeping your
            cryptocurrency wallet secure, just like you would with any other
            possessions. Here are a few pieces of advice:
          </p>
          <p className="pt-2">
            • Don't reuse passwords: Verify that none of your other accounts are
            using the same password as your crypto wallet.
          </p>
          <p className="pt-2">
            • Protect your seed phrase: When setting up your wallet, keep your
            seed phrase somewhere you won't lose it but that is out of the way
            of others. Never divulge your seed phrase to others.
          </p>
          <p className="pt-2">
            • Don't click links you don't recognize: This is particularly valid
            if you get a link straight from someone.
          </p>
        </section>
        <section id="Connecting" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            Connecting your crypto wallet to Universal Collective
          </h2>
          <p className="pt-2">
            You will be required to connect your wallet and approve each action
            you try to perform that will be recorded on the blockchain, such as
            buying an NFT. To buy, trade, make, or transfer NFTs using universal
            collective, your wallet must be active.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WhatIsACryptoWallet;
