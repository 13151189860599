import React from 'react';
import city2 from '../assets/images/cityscape.png';
//standard component to display information regarding minting
const WhatIsMinting = () => {
  return (
    <div className="flex flex-col md:flex-row text-white py-12 px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#minting">
                What is minting an NFT?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#purpose">
                What is the purpose of minting?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#creators">
                Minting for creators
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#collectors">
                Minting for collectors{' '}
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#HowToMint">
                How to mint on universal collective
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#NowWhat">
                I minted my NFT! Now what?
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="minting" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-2">
            What is minting an NFT?
          </h1>
          <h2 className="text-2xl font-bold mb-2">Introduction</h2>
          <p>
            The act of adding a digital item to the blockchain is known as
            "minting" an NFT. This proves its unchangeable record of ownership
            and legitimacy.
          </p>
        </section>
        <section id="purpose" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What is the{' '}
            <span className="text-blue-500">purpose of minting?</span>
          </h2>
          <p>
            Some users may have asked, "Why can't I just screenshot an NFT?"
            Minting is one of the solutions. An NFT is minted and then placed on
            the blockchain, where its legitimacy and owner are confirmed. The
            immutable history of an NFT begins with its minting because the
            blockchain record cannot be changed.
          </p>
        </section>
        <section id="creators" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            Minting for <span className="text-blue-500">creators</span>{' '}
          </h2>
          <p>
            SBy minting your work, you may create verifiable ownership and
            verifiable scarcity as a creator. The blockchain verifies the
            legitimacy of limited-edition digital works before they may be
            published by creators. Due to the clear and open ownership, artists
            can create exclusive communities and benefits for NFT holders.
          </p>
        </section>
        <section id="collectors" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            Minting for <span className="text-blue-500">collectors</span>
          </h2>
          <p>
            However, NFTs can be minted by anyone. NFT projects frequently use a
            mint to grant early access to their NFTs. Since the mint occurs when
            the NFT is written to the blockchain, when you mint an NFT from a
            project, you become the first person to ever acquire that NFT.
            Participating in a project's launch is frequently like purchasing a
            pack of Pokémon cards because you never know what uncommon card
            you'll get.
          </p>
        </section>
        <section id="HowToMint" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How to mint on{' '}
            <span className="text-blue-500">Universal Collective</span>
          </h2>
          <p>
            It's simple to mint NFTs on universal collective. Universal
            Collective also provides "lazy minting," a method that allows you to
            avoid paying gas fees to mint your NFTs, on the Ethereum network.
            When you're a lazy mint, you can offer your item on universal
            collective for sale, but it hasn't yet been added to the blockchain.
            The mint and the sale are integrated into one transaction when
            someone purchases your NFT, therefore the buyer is responsible for
            covering all gas costs.
          </p>
          <div className="px-8">
            <p className="pt-4">
              You must take the following actions to mint on universal
              collective:
            </p>
            <h3 className="pt-4 font-bold">Set up a crypto wallet</h3>
            <p>
              You'll need a crypto wallet to conduct any activity on the
              blockchain. Your NFTs and cryptocurrency will be stored here.
            </p>
            <h3 className="pt-4 font-bold">Create a collection</h3>
            <p>
              Create the collection your NFTs are a part of before you mint
              them. You should now give your collection a name, a description, a
              category, and any social media links.
            </p>
            <h3 className="pt-4 font-bold ">Upload your work</h3>
            <p>
              You're ready to start minting your NFTs after setting up your
              wallet and building a collection. For more comprehensive lessons
              on building NFTs, see our Help Centre.
            </p>
          </div>
        </section>
        <section id="NowWhat" className="mb-10">
          <h2 className="text-2xl font-bold ">
            I minted my NFT! <span className="text-blue-500">Now what?</span>
          </h2>
          <p>
            Congratulations! You have now successfully created an NFT! After
            minting your NFT, all that's left to do is wait and see if anyone
            finds it through search or category research. But here are some
            areas to start if you want to maintain the momentum:
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold ">Grow your community</h3>
            <p>
              Community is crucial in the NFT industry. Building a community
              around your initiative may be immensely satisfying, especially if
              it is focused on a particular issue, such as inclusivity or
              cuisine. Communities inside projects can be vibrant and close-knit
              even without a clear theme. Twitter and Discord are the most
              widely used platforms for community interaction in the NFT
              industry.
            </p>
            <h3 className="pt-4 font-bold ">Market your project</h3>
            <p>
              Understanding your audience and the value you are delivering to
              them is crucial when marketing your project. Is it the art itself,
              special advantages, the neighbourhood, or anything else? Make sure
              the benefits of purchasing your NFT are obvious to the public.
            </p>
            <p className="pt-4">
              Twitter, Instagram, and Reddit are excellent platforms to start
              promoting your work for social media marketing. Use appropriate
              hashtags and interact with other users in the space. Be genuine
              and accept criticism from others. Good fortune!
            </p>
          </div>
        </section>
        <img
          src={city2}
          alt="Image 1"
          className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
        />
      </div>
    </div>
  );
};

export default WhatIsMinting;
