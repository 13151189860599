import React from 'react';
import stackedblocks from '../assets/images/stackedblocks.png';
//standard component to display information regarding polygon
const WhatIsPolygon = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#Polygon">
                Introduction
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#MATIC">
                What is Polygon - MATIC?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#work">
                How does Polygon work?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#uses">
                What are Polygon's main uses?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#NFTs">
                What are Polygon NFTs?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#store">
                How can I buy, store, and use Polygon?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#compatible">
                Is Universal Collective compatible with Polygon?
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="Polygon" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-4">Polygon 101</h1>
          <h2 className="text-2xl font-bold mb-4">Introduction</h2>
          <p>
            Polygon is an EVM-compatible Ethereum sidechain that uses the
            Proof-of-Stake method of validation. This means Polygon is its own
            blockchain, but it benefits from and is compatible with Ethereum in
            a few important ways. Polygon's native token is MATIC. In 2022,
            Polygon pledged to go carbon neutral.
          </p>
        </section>
        <section id="MATIC" className="mb-10">
          <h2 className="text-2xl font-bold mb-4">
            What is <span className="text-blue-500">Polygon - MATIC?</span>
          </h2>
          <p>
            Polygon was created to reduce Ethereum transaction costs and improve
            speed. While Ethereum, a Layer 1 chain, is one of the most popular
            blockchains due to its secure and decentralized nature, its
            popularity has made transactions times longer and gas fees higher.
            Built on Ethereum's technology, Polygon was designed to be a
            scalable solution for some of Ethereum's pain points.
          </p>
          <p className="pt-4">
            Polygon was established in 2017 under the name MATIC and renamed
            Polygon in 2021 but retained the name “MATIC” for its
            cryptocurrency. MATIC is being used by tens of thousands of dApps
            for gas fees, is used on the Polygon blockchain to the stake, and
            can be used to buy Polygon NFTs.
          </p>
          <p className="pt-4">
            As a scaling platform, Polygon also offers a suite of solutions for
            developers.
          </p>
        </section>
        <section id="work" className="mb-10">
          <h2 className="text-2xl font-bold mb-4">
            How does <span className="text-blue-500">Polygon work?</span>{' '}
          </h2>
          <p>
            Think of Polygon as a sibling to Ethereum. It's a separate
            blockchain, but the two are compatible in specific ways. For
            example, wallet addresses on Polygon and Ethereum are the same on
            both chains. You can think of this as account numbers that are
            shared across multiple services. You can have multiple accounts, or
            wallets, on Polygon and Ethereum with different balances on each,
            but your wallet address serves as the common identifier on both.
            Polygon also benefits from Ethereum's security protocols.
          </p>
          <p className="pt-4">
            One thing that's important to note is that contract addresses are
            different on Polygon and Ethereum, and tokens from Polygon must be
            bridged to Ethereum to be usable there.
          </p>
          <p className="pt-4">
            Ethereum is a Layer 1 blockchain. Layer 1 blockchains are the main,
            or base level, blockchains. This primarily means they offer tools
            that allow other blockchains to build on top of them such as a base
            level of security, and core software development frameworks.
          </p>
          <p className="pt-4">
            Polygon is a sidechain created to scale effectively. A "sidechain"
            is a separate, independent blockchain that's linked to the Layer 1
            chain via a two-way bridge. Sidechains are designed to process
            transactions efficiently and are essentially self-contained
            blockchains responsible for their own security. What connects them
            to the main blockchain is that they use the same underlying
            technology and that they can transfer digital items to the main
            blockchain.
          </p>
          <p className="pt-4">Polygon's documentation says:</p>
          <p className="pt-4 px-5 italic">
            “Think of a Sidechain as a clone of a 'parent' blockchain,
            supporting [the] transfer of assets to and from the main chain. It
            is simply an alternate to [the] parent chain that creates a new
            blockchain with its own mechanism of creating blocks (consensus
            mechanism). Connecting a sidechain to a parent chain involves
            setting up a method of moving assets between the chains.”
          </p>
          <p className="pt-4">
            Developers typically decide which kind of blockchain to build on
            based on developer tools, gas fees, and processing speed. Polygon
            and other EVM-compatible sidechains help address scaling on Ethereum
            and reduce issues like high gas fees and slower speeds.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-4">
              What validation method does Polygon use?
            </h3>
            <p>
              Polygon uses the Proof-of-Stake method to validate each
              transaction added to the blockchain.
            </p>
            <p className="pt-4">
              In Polygon's Proof-of-Stake method, as a prerequisite to being
              allowed to validate, validators stake MATIC on the Ethereum
              blockchain. This process helps guarantee a blockchain's
              decentralized, secure, and public nature.
            </p>
          </div>
        </section>
        <section id="uses" className="mb-10">
          <h2 className="text-2xl font-bold mb-4">
            What are <span className="text-blue-500">Polygon's main uses?</span>
          </h2>
          <p>
            Polygon's advantages are its ability to process transactions faster
            and at lower gas fees than Ethereum while also being able to utilize
            Ethereum's decentralization and security.
          </p>
          <p className="pt-4">
            In web3, the term “gas fee” refers to the payment needed to execute
            transactions on the blockchain. These payments compensate the node
            operators who keep the blockchain functioning. This validation helps
            ensure the blockchain has a permanent, immutable record.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">Polygon DApps</h3>
            <p>
              Community is crucial in the NFT industry. Building a community
              around your initiative may be immensely satisfying, especially if
              it is focused on a particular issue, such as inclusivity or
              cuisine. Communities inside projects can be vibrant and close-knit
              even without a clear theme. Twitter and Discord are the most
              widely used platforms for community interaction in the NFT
              industry.
            </p>
            <h3 className="pt-4 font-bold mb-2">Polygon DeFi</h3>
            <p>
              Understanding your audience and the value you are delivering to
              them is crucial when marketing your project. Is it the art itself,
              special advantages, the neighbourhood, or anything else? Make sure
              the benefits of purchasing your NFT are obvious to the public.
            </p>

            <h3 className="pt-4 font-bold mb-2">Polygon NFTs</h3>
            <p>
              Non-Fungible Tokens (NFTs) are unique, digital items with
              blockchain-managed ownership. Examples of NFTs include digital
              art, collectibles, virtual reality items, crypto domain names,
              ownership records for physical assets, and more.
            </p>
          </div>
        </section>
        <section id="NFTs" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            What are <span className="text-blue-500">Polygon NFTs?</span>
          </h2>
          <p>
            Polygon NFTs are NFTs that have been minted onto the Polygon
            blockchain. Polygon is most frequently used for gaming NFTs, virtual
            world NFTs, and utility NFTs because of its lower gas fees and
            speed.
          </p>
        </section>
        <section id="store" className="mb-10">
          <h2 className="text-2xl font-bold mb-4">
            How can I <span className="text-blue-500">buy,</span>{' '}
            <span className="text-blue-500">store,</span> and{' '}
            <span className="text-blue-500">use</span> Polygon?
          </h2>
          <div className="flex ">
            <div className="w-full px-8">
              <h3 className="pt-4 font-bold mb-4">
                How do I buy a Polygon NFT?
              </h3>
              <p>
                Buying an NFT on Polygon requires using MATIC for the gas fee,
                but a Polygon NFT can be purchased with ETH for Polygon and some
                other Ethereum-compatible currencies that universal collective
                supports.
              </p>
              <p className="pt-4">
                To spend your Ethereum currencies on Polygon, you must first
                "bridge" them across to the Polygon blockchain. You will need to
                have an Ethereum-compatible wallet to bridge your ETH to
                Polygon. Once you have clicked through to your “Wallet Icon” on
                universal collective, you will see a “Bridge to Polygon” option.
                Here's our step-by-step guide.
              </p>
            </div>
            <div className="flex items-center ">
              <img
                src={stackedblocks}
                alt="stackedblockimage"
                className="px-8 pt-12"
              />
            </div>
          </div>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-4">
              What can I store on Polygon?
            </h3>
            <p>
              Once you have set up a Polygon-compatible crypto wallet, you can
              use it to access cryptocurrencies, NFTs, DApps, and DAOs. MetaMask
              and Coinbase Wallet are two popular wallets that support Polygon.
              Not all crypto wallets support NFTs, so make sure you double-check
              if you plan to buy and sell NFTs.
            </p>
            <h3 className="pt-4 font-bold mb-4">How can I use Polygon? </h3>
            <p>
              Universal Collective supports NFT creation and buying and selling
              across multiple blockchains. This guide explains how to use our
              Polygon marketplace to create and sell NFTs.
            </p>
          </div>
        </section>
        <section id="compatible" className="mb-10">
          <h2 className="text-2xl font-bold mb-4">
            Is Universal Collective{' '}
            <span className="text-blue-500">compatible with Polygon?</span>
          </h2>
          <p>
            Yes! Universal Collective supports Polygon-compatible wallets and
            Polygon-based NFTs. You can buy, sell, transfer, and mint Polygon
            NFTs all using universal collective. You can explore Polygon NFTs on
            universal collective by using tools like our Trending chart and
            filtering for Polygon.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WhatIsPolygon;
