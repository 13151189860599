import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { Link } from 'react-router-dom';
// standard functional component
const Success = () => {
  return (
    <section className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      {/* Container */}
      <div
        className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl
      rounded-2xl shadow-lx shadow-black text-center py-5
      flex flex-col justify-center items-center space-y-2"
      >
        {/* Checkmark icon */}
        <AiOutlineCheck className="text-green-800" size={60} />
        <h4 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl text-white">
          Payment successful
        </h4>
        <p className="text-gray-400">
          Your wallet have been topped up with the amount you paid for.
        </p>
        {/* Link to wallet */}
        <Link
          to="/wallet"
          className="bg-blue-500 hover:bg-blue-700 text-white
          font-bold py-2 px-4 rounded focus:outline-none
          focus:shadow-outline"
        >
          See wallet
        </Link>
      </div>
    </section>
  );
};
export default Success;
