import React from 'react';
import blckchain from '../assets/images/blckchain.png';
//standard component to display information regarding gas fees
const WhatAreGasFees = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#fees">
                Introduction
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#purpose">
                What is the purpose of gas fees?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#impacts">
                What impacts gas fees and how are they calculated?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#avoid">
                How can you avoid high gas fees?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#differ">
                How do gas fees differ by chain?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#transactions">
                Why use Universal Collective for your NFT transactions?
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="fees" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-4">What are gas fees?</h1>
          <h2 className="text-2xl font-bold mb-2">Introduction</h2>
          <p>
            "Gas fee" in web3 refers to the sum required to carry out a
            transaction on the blockchain. The node operators who keep the
            blockchain running are compensated by these payments. This
            verification makes sure the blockchain has an unchangeable,
            permanent record.
          </p>
          <div className="flex items-center justify-center p-10">
            <img src={blckchain} alt="blockchain image" />
          </div>
          <p className="pt-4">
            {' '}
            We'll go over the reason for gas fees, factors that affect them,
            ways to avoid paying excessive fees, how rates vary per blockchain,
            and how using universal collective makes it simple to keep costs
            down. Let's start now.
          </p>
        </section>
        <section id="purpose" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What is the{' '}
            <span className="text-blue-500">purpose of gas fees?</span>
          </h2>
          <p>
            Gas is referred to by Ethereum as "the fuel that allows the network
            to operate, in the same way that a car needs gasoline to run."
          </p>
          <p className="pt-4">
            {' '}
            The organisations known as node operators or network validators,
            which approve transactions on the blockchain, are paid through gas
            fees. Different gas costs apply to each of the global
            collective-supported blockchains (Ethereum, Polygon, Klaytn,
            Arbitrum, Optimism, Avalanche, and BNB Chain). Depending on how each
            chain validates transactions, these fees vary.
          </p>
          <p className="pt-4">
            {' '}
            The answer to the question of who receives the money from these gas
            costs depends on the technique each blockchain employs to validate
            transactions. Users frequently ask this question. So, let's take a
            step back and talk about the two main validation techniques: proof
            of stake and proof of work.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">
              Proof-of-Stake and Proof-of-Work
            </h3>
            <p>
              Validators are used in Proof-of-Stake blockchains to validate
              transactions. Users who stake a sizable quantity of bitcoin on
              that blockchain are known as validators. To ensure accuracy, these
              validators examine each transaction and keep an eye on all
              activity on the blockchain. Validators who use this method vote on
              the results.
            </p>
            <p className="pt-4">
              {' '}
              Miners are used by blockchains that employ the Proof-of-Work
              algorithm to validate transactions. Complex mathematical problems
              must be solved by miners to validate each transaction. The
              operators receive the gas fees since both procedures are
              intricate, time-consuming, and ultimately secure the security of
              the blockchain.
            </p>
            <h3 className="pt-10 font-bold mb-2">
              What is The Merge and how does it impact gas fees?
            </h3>
            <p>
              Although Ethereum had previously relied on the Proof-of-Work
              algorithm, it recently switched to the Proof-of-Stake algorithm as
              part of an occasion called "The Merge." The Ethereum Foundation
              estimates that this will result in a 99.95% reduction in energy
              use. According to the Foundation, "the Merge was a change of
              consensus mechanism, not an expansion of network capacity, and was
              never intended to lower gas fees."
            </p>
          </div>
        </section>
        <section id="impacts" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What impacts gas fees and{' '}
            <span className="text-blue-500">how are they calculated?</span>
          </h2>
          <p>
            As more people utilise the applications that are built on top of a
            blockchain, gas prices rise. As these users contend for space within
            the block, gas prices rise. Imagine it being like Uber's surge
            pricing system, which raises the price to reserve a ride during the
            busiest commute hours.
          </p>
          <p className="pt-4">
            {' '}
            When data is saved or modified, tokens are moved, NFTs are created,
            sold, or bought, among other things, fees are incurred. Each of
            these operations uniquely modifies the blockchain, necessitating a
            distinct gas fee. It's also important to note that gas fees don't
            change the price of the NFT you're buying, but they do change the
            purchasing of an NFT during a busy period when many users are also
            utilising the network may result in a higher overall cost.
          </p>
        </section>
        <section id="avoid" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How can you avoid{' '}
            <span className="text-blue-500">high gas fees?</span>
          </h2>
          <p>
            Gas costs are not regulated by, established by, or collected from
            users of the site by Universal Collective. Instead, they are all
            distributed to miners or network validators.
          </p>
          <p className="pt-4">
            Try doing your transactions at times when there are fewer users on
            the network, such as early in the morning or in the middle of the
            day when everyone is at work, to assist in reducing excessive gas
            fees. When you use universal collective to begin the NFT purchasing
            process, your wallet provider will break down the gas fee so you can
            monitor it update and finish the transaction when it is low. On
            EthereumPrice.Org/Gas, you may get historical and current gas prices
            for Ethereum, which will help you identify periods with the least
            activity.
          </p>
          <p className="pt-4">
            Additionally, you can reduce costs by employing chains like Polygon
            and Optimism that use less gas (more on that later!).
          </p>
          <div className="px-8">
            <h3 className="pt-6 font-bold mb-2">Types of fees</h3>
            <p>
              Users of universal collective are charged one-time costs and
              ongoing payments, respectively. Users must pay a few one-time
              costs while carrying out specific tasks for the first time.
              Certain permissions are granted by these transactions. When users
              accept an offer, transfer an NFT, purchase an NFT, cancel an
              auction or bid, convert ETH to WETH (or vice versa), freeze
              metadata, bridge ETH, or withdraw ETH to and from Polygon, they
              are charged recurring fees.
            </p>
            <h3 className="pt-6 font-bold mb-2">
              What is lazy minting and how does it affect gas fees?
            </h3>
            <p>
              It frequently costs money to mint NFTs. Lazy minting is the first
              method to manufacture and sell NFTs on the Ethereum or Polygon
              blockchains without paying gas fees, and Universal Collective is
              happy to offer it. The on-chain components of establishing an NFT
              are separated from the metadata related to its production by lazy
              minting.
            </p>
            <p className="pt-4">
              Most acts on universal collective don't include paying for gas.
              Gas is not needed for browsing, favourite-making, lazy minting new
              NFTs, building collections, initialising accounts, adding new NFTs
              to collections at a fixed price or in an auction, or lowering the
              price of listed NFTs. Most times, actions that write data to the
              blockchain require gas.
            </p>
          </div>
        </section>
        <section id="differ" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How do gas fees{' '}
            <span className="text-blue-500">differ by chain?</span>
          </h2>
          <p>
            Currently, each of the blockchains that Universal Collective is
            compatible with—Ethereum, Polygon, Klaytn, Arbitrum, Optimism,
            Avalanche, and BNB Chain—has a separate gas charge associated with
            network transactions.
          </p>
          <p className="pt-4">
            Let's look at the other chains now that we've covered Ethereum gas
            costs and their calculations.
          </p>
          <div className="px-8">
            <h3 className="pt-6 font-bold mb-2">
              EVM-compatible chain gas fees
            </h3>
            <p>
              As EVM-compatible chains, Polygon, Arbitrum, and Optimism are
              theoretically compatible with Ethereum and support token transfers
              between the two networks. Chains that are EVM-compatible operate
              more effectively and frequently charge cheaper transaction costs.
              minting.
            </p>
            <h3 className="pt-4 font-bold mb-2">Klaytn gas fees</h3>
            <p>
              A blockchain compatible with Ethereum and created expressly for
              using the metaverse is called Klaytn. For individuals who are
              interested in that element of web3, Klaytn serves as a one-stop
              shop by providing users with a range of tools designed
              specifically for usage in the metaverse. It has cheap gas costs
              and is incredibly quick. For instances like in-game NFT drops,
              this is helpful.
            </p>
          </div>
        </section>
        <section id="transactions" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            Why use Universal Collective for your
            <span className="text-blue-500"> NFT transactions?</span>
          </h2>
          <p>
            The universal collective is compatible with numerous chains and has
            minimal gas costs, as was stated in the section above. Since we are
            aware that every chain has advantages of its own and is continuously
            changing, the universal collective is here to provide you with a
            variety of options when minting, purchasing, or selling NFTs.
          </p>
          <p className="pt-4">
            Additionally, the Seaport new protocol, employed by universal
            collective, greatly reduces gas costs for NFT transactions. Since
            the launch of Seaport, we've assisted users in saving over 35% on
            transactional gas costs.
          </p>
          <p className="pt-4">
            We provide you with the opportunity to top off your wallet using a
            credit or debit card if you realise while checking out that you do
            not have enough cryptocurrency to finish your purchase. This
            expedites and simplifies the purchasing process.
          </p>
          <p className="pt-4">
            Finally, we offer Lazy Minting as a free tool for users to create
            NFTs. Universal Collective is here to offer you the best range of
            NFTs on several blockchains as the biggest and most diverse NFT
            marketplace.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WhatAreGasFees;
