import React from 'react';
import web3 from '../assets/images/web3.png';
//standard component to display information regarding web3
const WhatIsWeb3 = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#intro">
                Introduction
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#web3">
                What is web3?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#1">
                What were Web 1.0 and Web 2.0?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#principles">
                What are the core principles of web3?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#used">
                How is web3 being used now?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#future">
                The future of Web3
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="intro" className="mb-10">
          <h1 className="text-3xl font-extrabold mb-4">Web3</h1>
          <h2 className="text-2xl font-bold mb-2">Introduction</h2>
          <p>
            The idea of a decentralised internet based on blockchain technology
            is known as Web3. Web3 represents a transition to a decentralised,
            public internet centred on the concept of ownership. If Web 1.0 was
            the creation of the Internet web 2.0 would see us move to a social
            platform-centric Internet. In essence, Web3 returns ownership and
            power to the users, aiming to level the playing field for users and
            reduce the outside influence from third parties. All the components
            of this ecosystem, including cryptocurrency, blockchain technology,
            decentralised finance (also known as "DeFi"), NFTs, the metaverse,
            and decentralised apps (often known as "dApps"), are together
            referred to as the "web3."
          </p>
        </section>
        <section id="web3" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What is <span className="text-blue-500">web3?</span>
          </h2>
          <p>
            The phrase was first used in a blog post in 2014 by Gavin Wood, who
            is also the co-founder of the Web3 Foundation and the Ethereum
            blockchain. He stated:
          </p>

          <p className="pt-4 px-10 italic">
            WEB 3.0, OR AS MIGHT BE TERMED THE “POST-SNOWDEN” WEB, IS A
            RE-IMAGINATION OF THE SORTS OF THINGS WE ALREADY USE THE WEB FOR,
            BUT WITH A FUNDAMENTALLY DIFFERENT MODEL FOR THE INTERACTIONS
            BETWEEN PARTIES. INFORMATION THAT WE ASSUME TO BE PUBLIC, WE
            PUBLISH. INFORMATION WE ASSUME TO BE AGREED UPON, WE PLACE ON A
            CONSENSUS LEDGER. INFORMATION THAT WE ASSUME TO BE PRIVATE, WE KEEP
            SECRET AND NEVER REVEAL.
          </p>
          <p className="pt-">
            In his piece, he concentrated on the four key elements he thought
            would make up this third wave of the internet: "static content
            publication, dynamic messages, trustless transactions, and an
            integrated user interface." A large portion of web3 today is based
            on these ideas.
          </p>
          <div className="flex items-center justify-center">
            <img src={web3} alt="world" className="w-1/2" />
          </div>
        </section>
        <section id="1" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What were <span className="text-blue-500">Web 1.0</span> and{' '}
            <span className="text-blue-500">Web 2.0?</span>
          </h2>
          <p>
            In the late 1990s and early 2000s, when the internet first became
            widely used, text-based content was mostly sought out there. Web 1.0
            was the first version of the Internet, and it was characterised by
            message boards, business websites, and personal blogs. Most of the
            content was static, which is just another way of stating that Web
            1.0 sites weren't very dynamic. This is often referred to as the
            "read-only" web.
          </p>
          <p className="pt-4">
            The second phase of the Internet, known as Web 2.0, was
            predominantly characterised by a shift to mobile Internet and was
            driven by social media platforms and significant financial
            institutions. Most of the internet still makes use of Web 2.0's
            institutions and platforms. If you're unsure whether you utilise Web
            2.0, consider the apps you used to communicate with your loved ones
            today and the method you used to check your checking account's
            balance most recently.
          </p>
          <p className="pt-4">
            With the introduction of Web 2.0, users began contributing content
            alongside the websites and applications they were using (contemplate
            setting up and personalising your social network profile). The
            photographs, data, and information you submit on these platforms
            belong to the companies who own them, not to you, even if you've
            customised your profile and made it feel like it's your own. Web 2.0
            is often referred to as the "read-write" web.
          </p>
          <p className="pt-4">
            We now arrive at web3. Large enterprises are no longer the only
            owners of web3 in their ideal state. Instead, it is firmly in the
            hands of the users. And the blockchain's decentralised structure,
            which enables users to document the creation and ownership of their
            goods openly, permanently, and immutably, makes this possible. The
            term "read-write-own" is now used to describe this web.
          </p>
        </section>
        <section id="principles" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            What are the core{' '}
            <span className="text-blue-500">principles of web3?</span>
          </h2>
          <p>
            Most importantly, it's decentralised. Web3 enables people to own
            their information and things and distribute them in any way they see
            fit rather than being controlled by companies and other major
            entities that can take users' information and utilise it however
            they choose.
          </p>
          <p className="pt-4">
            Do you recall when Gavin Wood listed "trustless transactions" as one
            of the most crucial features of the future internet? That wasn't an
            accident. He observed how users had to place their faith in other
            parties to interact online in Web 1.0 and Web 2.0, and he imagined a
            world in which this was no longer essential.
          </p>
          <p className="pt-4">
            Blockchain technology can help in this situation. In essence, the
            blockchain is a sizable digital public record. Many nodes (i.e.,
            individual computers) make up the most widely used blockchains,
            which is why they are referred to as "decentralised." As a result,
            the blockchain is dispersed across a peer-to-peer network rather
            than being hosted by a single corporate server. This guarantees that
            the blockchain won't be altered. Transactions on the blockchain can
            also be "trustless" in the sense that you don't need to place your
            trust in anyone to complete them because the blockchain records,
            preserve history, and acts as an impartial party. Like this, the
            transactions are "permissionless" in the sense that they don't need
            the consent of a third party because they are carried out by a
            network of computers.
          </p>
          <p className="pt-4">
            The adoption of cryptocurrencies by Web3 gives customers an
            alternative to the established banking system. However, it still
            allows users to function outside of conventional centralised
            institutions. Many people still utilise a combination of government
            cash held by banks and cryptocurrencies.
          </p>
        </section>
        <section id="used" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            How is web3 being <span className="text-blue-500">used now?</span>
          </h2>
          <p>
            Web3 will continue to grow and change over the upcoming years, but
            for now, its main applications are focused on ownership and
            financial transactions.
          </p>
          <div className="px-8">
            <h3 className="pt-4 font-bold mb-2">NFTs</h3>
            <p>
              A non-fungible token (NFT) is a special digital object kept on a
              blockchain. NFTs are used as a digital record of ownership and can
              represent nearly anything. NFTs include artwork, profile pictures,
              collectibles, domain names, tickets, memberships, games, gaming
              tools, virtual worlds, and a variety of other items that are
              constantly expanding. Identity verification, intellectual
              property, and storage solutions are examples of recent and
              developing application cases.
            </p>
            <h3 className="pt-4 font-bold mb-2">
              Decentralized Finance (DeFi)
            </h3>
            <p>
              All financial services that employ blockchain technology are
              referred to as decentralised finance, or just "DeFi," for short.
              Trustless, permissionless, and quick transactions are made
              possible by DeFi.
            </p>
            <h3 className="pt-4 font-bold mb-2">Cryptocurrency</h3>
            <p>
              Digital currency supported by the blockchain is known as
              cryptocurrency. This makes it possible for a network of computers
              to verify transactions as opposed to a single organisation or
              authority.
            </p>
            <h3 className="pt-4 font-bold mb-2">DAOs</h3>
            <p>
              Decentralised autonomous organisations, or DAOs, are groups
              without a single leader. Instead, they are controlled by the user
              base that makes up the organisation. DAO’s users frequently
              produce ideas that are put to a vote to make changes. DAOs have
              proprietary tokens that users can use to demonstrate membership
              and cast votes. DAOs can be used for a variety of objectives,
              including fundraising, business networking, and education.
            </p>
            <h3 className="pt-4 font-bold mb-2">dApps</h3>
            <p>
              "Decentralised applications" is abbreviated as "dApps." dApps use
              blockchain technology, but they do not need to be decentralised
              themselves, unlike the web 2.0 apps that are owned by a single
              company. DApps can be run using traditional hierarchical
              structures or peer-to-peer networks on the blockchain, but what
              distinguishes them from other applications is their use of
              decentralised protocols. authority.
            </p>
            <h3 className="pt-4 font-bold mb-2">Metaverse</h3>
            <p>
              The term "metaverse" describes a hybrid of virtual and augmented
              reality where users can engage with the online world. The term
              "metaverse" characterises how people interact with the internet,
              not where it takes place, making it less of a place and more of a
              modality. Users can play games, change how they look, interact
              with other players, and communicate in the metaverse. It's a novel
              approach for individuals to socialise while utilising VR and AR.
              Although not all metaverses utilise or will employ web3 technology
              in their construction, those that do let their users own their
              items in the metaverse.
            </p>
          </div>
        </section>
        <section id="future" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            The <span className="text-blue-500">future</span> of Web3
          </h2>
          <p>
            Web3 is still a relatively young idea, and it will undoubtedly
            develop more over the course of our lifetimes. As the components are
            embraced more broadly and are further incorporated into our current
            technology, it's conceivable that the name "web3" will soon seem
            archaic.
          </p>
          <p className="pt-4">
            The development of web3 will likely lead to advancements in digital
            storage (moving away from centralised cloud providers and towards
            decentralised solutions), faster and more secure web browsers,
            significant changes in how we interact with financial systems,
            improved online gaming and social experiences, new social networks
            that enable users to fully own their data, and even new desktop and
            mobile operating systems.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WhatIsWeb3;
