import React from 'react';
import metamask1 from '../assets/images/metamask1.png';
import metamask2 from '../assets/images/metamask2.png';
import metamask3 from '../assets/images/metamask3.png';
import metamask4 from '../assets/images/metamask4.png';
import metamask5 from '../assets/images/metamask5.png';
import metamask6 from '../assets/images/metamask6.png';
import metamask7 from '../assets/images/metamask7.png';
import metamask8 from '../assets/images/metamask8.png';
import metamask9 from '../assets/images/metamask9.png';
import metamask10 from '../assets/images/metamask10.png';
import metamask11 from '../assets/images/metamask11.png';

const HowToSetUpYourWallet = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
        <div className="w-full md:w-1/4 hidden md:block">
          <div className="sticky top-10">
            <h2 className="text-lg font-bold my-4">Contents</h2>
            <ul className="space-y-2">
              <li>
                <a className="hover:text-blue-400" href="#Download">
                  Download MetaMask
                </a>
              </li>
              <li>
                <a className="hover:text-blue-400" href="#Create">
                  Create a MetaMask wallet
                </a>
              </li>
              <li>
                <a className="hover:text-blue-400" href="#Connect">
                  Connect MetaMask to your NFT website
                </a>
              </li>
              <li>
                <a className="hover:text-blue-400" href="#Using">
                  Using MetaMask on your NFT website
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-3/4 ml-0 md:ml-8">
          <h1 className="text-3xl font-extrabold mb-4">MetaMask wallet 101</h1>
          <section id="Download" className="mb-4">
            <h2 className="text-2xl font-bold mb-4">
              <span className="text-blue-500">Download </span>MetaMask
            </h2>
            <div className="px-8">
              <p className="pb-5">
                Step 1. Visit the official MetaMask website at
                <a
                  href="https://metamask.io/"
                  rel="metamask"
                  className="font-bold"
                  target="_blank"
                >
                  {' '}
                  metamask.io{' '}
                </a>
                MetaMask is available as a browser extension for Chrome,
                Firefox, Brave, and Edge.
              </p>
              <img
                src={metamask1}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="py-10">
                Step 2. Once on the MetaMask website, you'll see options to
                download the extension for different browsers. Click on the "Get
                Chrome Extension" or "Get Firefox Extension" button, depending
                on your preferred browser.
              </p>
              <img
                src={metamask2}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="py-8">
                You will be redirected to the respective browser's extension
                store: the Chrome Web Store or the Firefox Add-ons page.
              </p>
              <img
                src={metamask3}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8">
                Step 3. On the extension store page, click on the "Add to
                Chrome" or "Add to Firefox" button to start the installation
                process.
              </p>
              <p className="pt-2 pb-8">
                Step 4. A pop-up will appear, asking for confirmation to add the
                extension. Click "Add extension" to proceed with the
                installation.
              </p>
              <img
                src={metamask4}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="py-8 text-center">
                The MetaMask extension will be downloaded and installed in your
                browser.
              </p>
              <img
                src={metamask5}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8 pb-5 text-center">
                Once installed, the MetaMask icon will appear in your browser's
                toolbar.
              </p>
            </div>
          </section>
          <section id="Create" className="mb-4">
            <h2 className="text-2xl font-bold mb-4">
              <span className="text-blue-500">Create</span> a MetaMask wallet
            </h2>
            <div className="px-8">
              <p>
                Step 1. Click on the MetaMask icon in your browser's toolbar to
                launch MetaMask also displayed above.
              </p>
              <p className="pt-2">
                A new tab will open, displaying the MetaMask welcome screen.
              </p>
              <p className="pt-2 pb-8">
                If you are using MetaMask for the first time, you will be
                prompted to create a new wallet. Click on the "Get Started"
                button.
              </p>
              <img
                src={metamask6}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8">
                Step 2. You will be presented with two options: "Import Wallet"
                or "Create Wallet." Choose the "Create Wallet" option to create
                a new wallet.
              </p>
              <p className="pt-2 pb-8">
                Enter a strong password for your wallet. This password will be
                required to unlock your MetaMask wallet in the future. Make sure
                to use a unique and secure password.
              </p>
              <img
                src={metamask7}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8">
                Step 3. After entering the password, click on the "Create"
                button.
              </p>
              <p className="pt-2">
                MetaMask will generate a secret backup phrase consisting of 12
                random words. This backup phrase is crucial for wallet recovery,
                so store it securely offline. Click on the "Next" button.
              </p>
              <p className="pt-2">
                Confirm the backup phrase by selecting the words in the correct
                order. This step ensures that you have accurately saved and
                noted down the backup phrase.
              </p>
              <p className="pt-2 pb-8">
                Once the backup phrase is confirmed, your MetaMask wallet will
                be created.
              </p>
              <img
                src={metamask8}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
            </div>
          </section>
          <section id="Connect" className="pt-8 mb-4">
            <h2 className="text-2xl font-bold mb-4">
              <span className="text-blue-500">Connect</span> MetaMask to your
              NFT website
            </h2>
            <div className="px-8">
              <p className="pb-8">
                On your NFT website, provide a prominent button or link that
                allows users to connect MetaMask.
              </p>
              <img
                src={metamask9}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8">
                When the user clicks on the connect button, your website should
                detect whether MetaMask is installed and activated in the user's
                browser.
              </p>
              <img
                src={metamask10}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8">
                If MetaMask is not detected, you can provide a prompt with a
                link to download and install MetaMask as mentioned in the
                previous steps.
              </p>
              <p className="pt-2">
                If MetaMask is detected, a MetaMask prompt will appear, asking
                the user to grant permission for the website to connect to their
                MetaMask wallet.
              </p>
              <p className="pt-2 pb-8">
                The user must review the permission request and click on
                "Connect" or "Authorize" to establish the connection between
                MetaMask and your NFT website.
              </p>
              <img
                src={metamask11}
                alt="Image 1"
                className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
              />
              <p className="pt-8">
                After granting permission, MetaMask will share the user's
                Ethereum address and other necessary details with the website.
              </p>
              <p className="pt-2">
                Your NFT website can now interact with the user's MetaMask
                wallet to perform various actions, such as buying, selling, or
                transferring NFTs.
              </p>
            </div>
          </section>
          <section id="Using" className="mb-4">
            <h2 className="text-2xl font-bold mb-4">
              <span className="text-blue-500">Using MetaMask</span> on your NFT
              website
            </h2>
            <p>
              Once connected, your NFT website can access the user's Ethereum
              address through MetaMask.
            </p>
            <p className="pt-2">
              Users can initiate transactions, sign messages, and interact with
              smart contracts on your website using MetaMask.
            </p>
            <p className="pt-2">
              It's crucial to provide clear instructions and guidance on your
              website regarding how to use MetaMask for different actions, such
              as purchasing NFTs or interacting with specific features.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HowToSetUpYourWallet;
