import RoyaltyField from '../components/RoyaltyField';
import Withdrawal from '../components/Withdrawal';
import { setGlobalState, useGlobalState } from '../store';

const Admin = () => {
  // Accessing the platformRevenue state using useGlobalState
  const [platformRevenue] = useGlobalState('platformRevenue');
  // Accessing the royaltyFeePercent state using useGlobalState
  const [royaltyFeePercent] = useGlobalState('royaltyFeePercent');

  return (
    <section className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div
        className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl
      rounded-2xl shadow-lx shadow-black text-center py-5
      flex flex-col justify-center items-center space-y-2"
      >
        <div className="flex text-slate-300 justify-start items-center space-x-2">
          <h2 className="text-xl font-semibold">Royalty Balance:</h2>
          <h2 className="text-xl">{platformRevenue}</h2>
        </div>
        {/* Rendering the RoyaltyField component with the current royalty fee percentage */}
        <RoyaltyField currPct={royaltyFeePercent} />

        <button
          className="bg-blue-500 p-2 px-6 rounded-full text-white shadow-md
          shadow-gray-300 transform transition-transform duration-30 w-fit"
          onClick={() => setGlobalState('withdrawal', 'scale-100')}
        >
          Split Payment
        </button>
      </div>
      {/* Rendering the Withdrawal component */}
      <Withdrawal />
    </section>
  );
};
export default Admin;
