import React from 'react';
import SellNFT1 from '../assets/images/SellNFT1.png';
import SellNFT2 from '../assets/images/SellNFT2.png';
import SellNFT3 from '../assets/images/SellNFT3.png';
import SellNFT4 from '../assets/images/SellNFT4.png';

const HowToSellAnNFTOnUC = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#Why">
                SellIng my NFTs using Universal Collective?
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <h1 className="text-3xl font-extrabold mb-4">How to Sell an NFT</h1>
        <section id="Why" className="mb-8">
          <h2 className="text-2xl font-bold mb-2">
            SellIng my NFTs using Universal Collective?
          </h2>
          <p className="pb-8">
            Once you have created/minted your NFT you can offer it on Universal
            Collective.
          </p>
          <img
            src={SellNFT1}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />

          <p className="w-3/5 mx-auto py-8">
            If you choose to offer your NFT you will be presented with this
            screen where you can modify the length of how long, you want your
            NFT to be displayed under current auctions on the homepage.
          </p>
          <img
            src={SellNFT2}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <p className="w-2/5 mx-auto py-8">
            When you offer the item, you will be presented with 2 different
            screens, this one is the signing of the contract.
          </p>
          <img
            src={SellNFT3}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <p className="w-2/5 mx-auto py-8">
            This one outlines the gas fee price that it will cost to push it
            live to the chain.
          </p>
          <img
            src={SellNFT4}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <p className="w-2/5 mx-auto py-8">
            Now that you are live people may bid/purchase your NFT.{' '}
          </p>
        </section>
      </div>
    </div>
  );
};

export default HowToSellAnNFTOnUC;
