import { truncate, useGlobalState } from '../store';
import Countdown from '../components/Countdown';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getNFT } from '../services/api';

// Define the OffchainNFT component
const OffchainNFT = () => {
  // Initialize state variables
  const [auction, setAuction] = useState(null);
  const [connectedAccount] = useGlobalState('connectedAccount');
  const { id } = useParams();

  // Fetch NFT data from the API when the component mounts
  useEffect(async () => {
    const nft = await getNFT(id);
    setAuction(nft);
  }, []);
  // Render the component
  return (
    <div
      className="grid lg:grid-cols-2 gaps-6
        md:gap-4 lg:gap-3 py-2.5 text-white font-sans capitalize
        w-4/5 mx-auto mt-11"
    >
      <div
        className="h-[400px] bg-gray-800 rounded-md shadow-xl
        shadow-black md:w-4/5 md:items-center lg:w-4/5 md:mt-0"
      >
        <img
          src={auction?.image}
          alt={auction?.name}
          className="object-contain w-full h-80 mt-10"
        />
      </div>

      <div>
        {/* Render the Details component, passing the auction and connected account */}
        <Details auction={auction} account={connectedAccount} />
        {/* Render the CountdownNPrice component, passing the auction */}
        <CountdownNPrice auction={auction} />
      </div>
    </div>
  );
};

// Define the Details component
const Details = ({ auction, account }) => {
  // Render the auction details
  return (
    <div className="py-2">
      <h1 className="font-bold text-lg mb-1">{auction?.name}</h1>
      <p className="font-semibold text-sm">
        <span className="text-green-500">
          @
          {auction?.owner == account
            ? 'You'
            : auction?.owner
            ? truncate(auction?.owner, 4, 4, 11)
            : null}
        </span>
      </p>
      <p className="text-sm py-2">{auction?.description}</p>
    </div>
  );
};

// Define the CountdownNPrice component
const CountdownNPrice = ({ auction }) => {
  // Render the current price and countdown (if available)
  return (
    <div className="flex justify-between items-center py-5">
      <div>
        <span className="font-bold">Current Price</span>
        <p className="text-sm font-light">{auction?.price} ETH</p>
      </div>

      <div className="lowercase">
        <span className="font-bold">
          {/* Render the Countdown component if the duration is in the future */}
          {auction?.duration > Date.now() ? (
            <Countdown timestamp={auction?.duration} />
          ) : (
            '00:00:00'
          )}
        </span>
      </div>
    </div>
  );
};

export default OffchainNFT;
