import abi from '../abis/src/contracts/Auction.sol/Auction.json'
import address from '../abis/contractAddress.json'
import { ethers } from 'ethers'
import { getGlobalState, setGlobalState } from '../store'
import { authUser, getCollection } from './api'

const { ethereum } = window
const ContractAddress = address.address
const ContractAbi = abi.abi
let tx

const toWei = (num) => ethers.utils.parseEther(num.toString())
const fromWei = (num) => ethers.utils.formatEther(num)

const getCoinBalance = async (account) => {
  try {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const balance = await provider.getBalance(account)

    return fromWei(balance)
  } catch (error) {
    reportError(error)
  }
}

const getEthereumContract = async () => {
  let provider, signer
  const connectedAccount = getGlobalState('connectedAccount')

  if (connectedAccount) {
    provider = new ethers.providers.Web3Provider(ethereum)
    signer = provider.getSigner()
  } else {
    provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL
    )
    const wallet = ethers.Wallet.createRandom()
    signer = provider.getSigner(wallet.address)
  }

  const contract = new ethers.Contract(ContractAddress, ContractAbi, signer)
  return contract
}

const isWalletConnected = async () => {
  try {
    if (!ethereum) return reportError('Please install Metamask')

    const accounts = await ethereum.request({ method: 'eth_accounts' })
    setGlobalState('connectedAccount', accounts[0]?.toLowerCase())

    window.ethereum.on('chainChanged', (chainId) => window.location.reload())

    window.ethereum.on('accountsChanged', async () => {
      window.location.reload()
    })

    if (accounts.length) {
      setGlobalState('connectedAccount', accounts[0]?.toLowerCase())
    } else {
      console.log('No accounts found')
    }
  } catch (error) {
    reportError(error)
  }
}

const connectWallet = async () => {
  try {
    if (!ethereum) return reportError('Please install Metamask')
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
    setGlobalState('connectedAccount', accounts[0]?.toLowerCase())
  } catch (error) {
    reportError(error)
  }
}

const withdrawTo = async (beneficiaries, percentages, amount) => {
  if (!ethereum) return reportError('Please install Metamask')
  return new Promise(async (resolve, reject) => {
    try {
      const connectedAccount = getGlobalState('connectedAccount')
      const contract = await getEthereumContract()

      tx = await contract.withdrawTo(
        beneficiaries,
        percentages,
        toWei(amount),
        {
          from: connectedAccount,
        }
      )

      await tx.wait()
      await loadAdmin()

      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const performVerification = async () => {
  return new Promise(async (resolve, reject) => {
    if (!ethereum) return reportError('Please install Metamask')
    try {
      const message = `By signing this transaction, you agree to our terms of services.`

      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner()
      const signature = await signer.signMessage(message)
      const actualAddress = ethers.utils
        .verifyMessage(message, signature)
        ?.toLowerCase()
      const connectedAccount = getGlobalState('connectedAccount')

      if (actualAddress !== connectedAccount) {
        setGlobalState('connectedAccount', '')
        reject('Invalid account')
      } else {
        const formData = new FormData()
        formData.append('owner', connectedAccount)
        const authed = await authUser(formData)
        resolve(authed)
      }
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const updatePrice = async (tokenId, price) => {
  if (!ethereum) return reportError('Please install Metamask')
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await getEthereumContract()
      const user = await performVerification()

      tx = await contract.chanagePrice(tokenId, toWei(price), {
        from: user.owner,
      })

      await tx.wait()
      await loadCollection()
      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const purchaseNFT = async ({ tokenId, price }) => {
  if (!ethereum) return reportError('Please install Metamask')
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await getEthereumContract()
      const user = await performVerification()

      tx = await contract.buyAuctionedItem(tokenId, {
        from: user.owner,
        value: toWei(price),
      })

      await tx.wait()
      await loadLiveAuctions()
      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const placeBid = async ({ tokenId, price }) => {
  if (!ethereum) return reportError('Please install Metamask')
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await getEthereumContract()
      const user = await performVerification()

      tx = await contract.placeBid(tokenId, {
        from: user.owner,
        value: toWei(price),
      })

      await tx.wait()
      await loadAuction(tokenId)
      await loadLiveAuctions()
      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const claimPrize = async (tokenId, bidderId) => {
  if (!ethereum) return reportError('Please install Metamask')
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await getEthereumContract()
      const user = await performVerification()

      tx = await contract.claimPrize(tokenId, bidderId, {
        from: user.owner,
      })

      await tx.wait()
      await loadAuction(tokenId)
      await loadBidders(tokenId)
      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const changeRoyalty = async (newPct) => {
  if (!ethereum) return reportError('Please install Metamask')
  return new Promise(async (resolve, reject) => {
    const contract = await getEthereumContract()

    try {
      tx = await contract.setRoyaltyFee(newPct)

      await tx.wait()
      await loadAdmin()

      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const lazyOfferItemOnMarket = async ({
  name,
  description,
  image,
  tokenURI,
  price,
  biddable,
  expiresAt,
}) => {
  return new Promise(async (resolve, reject) => {
    if (!ethereum) return reportError('Please install Metamask')

    try {
      const contract = await getEthereumContract()
      const user = await performVerification()
      tx = await contract.lazyList(
        name,
        description,
        image,
        tokenURI,
        toWei(price),
        biddable,
        expiresAt,
        {
          from: user.owner,
        }
      )

      await tx.wait()
      await loadCollection()
      await loadLiveAuctions()
      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const offerItemOnMarket = async ({ tokenId, biddable, expiresAt }) => {
  return new Promise(async (resolve, reject) => {
    if (!ethereum) return reportError('Please install Metamask')

    try {
      const contract = await getEthereumContract()
      const user = await performVerification()
      tx = await contract.offerAuction(tokenId, biddable, expiresAt, {
        from: user.owner,
      })

      await tx.wait()
      await loadCollection()
      await loadLiveAuctions()
      resolve(tx)
    } catch (error) {
      reportError(error)
      reject(error)
    }
  })
}

const loadCollection = async (connectedAccount) => {
  try {
    if (!ethereum) return reportError('Please install Metamask')
    const contract = await getEthereumContract()

    const unminted = await getCollection(connectedAccount)
    setGlobalState('unminted', unminted)

    const minted = await contract.getMyAuctions({ from: connectedAccount })
    setGlobalState('minted', structuredAuctions(minted))
  } catch (error) {
    reportError(error)
  }
}

const loadBidders = async (tokenId) => {
  try {
    const contract = await getEthereumContract()
    const bidders = await contract.getBidders(tokenId)
    setGlobalState('bidders', structuredBidders(bidders))
  } catch (error) {
    reportError(error)
  }
}

const loadAuction = async (tokenId) => {
  try {
    const contract = await getEthereumContract()
    const auction = await contract.getAuction(tokenId)
    const owner = await contract.owner()

    setGlobalState('auction', structuredAuctions([auction])[0])
    setGlobalState('platformAcc', owner.toLowerCase())
  } catch (error) {
    reportError(error)
  }
}

const loadAdmin = async () => {
  try {
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()

    const owner = await contract.owner()
    const platformRevenue = await contract.platformRevenue()
    const royaltyFeePercent = await contract.royaltyFeePercent()

    setGlobalState('admin', connectedAccount == owner.toLowerCase())
    setGlobalState('platformRevenue', fromWei(platformRevenue))
    setGlobalState('royaltyFeePercent', royaltyFeePercent.toNumber())
  } catch (error) {
    reportError(error)
  }
}

const loadLiveAuctions = async () => {
  try {
    const contract = await getEthereumContract()
    let auctions = await contract.getAuctions()
    auctions = structuredAuctions(auctions)
    auctions = auctions.filter(
      (auction) => auction.duration > Date.now() && !auction.sold
    )
    setGlobalState('auctions', auctions)
  } catch (error) {
    reportError(error)
  }
}

const reportError = (error) => {
  console.log(error)
}

const structuredAuctions = (auctions) =>
  auctions
    .map((auction) => ({
      tokenId: auction.tokenId.toNumber(),
      owner: auction.owner.toLowerCase(),
      seller: auction.seller.toLowerCase(),
      winner: auction.winner.toLowerCase(),
      name: auction.name,
      description: auction.description,
      duration: Number(auction.duration),
      bids: auction.bids.toNumber(),
      image: auction.image,
      price: fromWei(auction.price),
      biddable: auction.biddable,
      sold: auction.sold,
      live: auction.live,
      tokenURI: auction.tokenURI,
    }))
    .reverse()

const structuredBidders = (bidders) =>
  bidders
    .map((bidder, i) => ({
      bidderId: i,
      timestamp: Number(bidder.timestamp),
      bidder: bidder.bidder.toLowerCase(),
      price: fromWei(bidder.price),
      refunded: bidder.refunded,
      won: bidder.won,
    }))
    .sort((a, b) => b.price - a.price)

export {
  isWalletConnected,
  connectWallet,
  loadCollection,
  updatePrice,
  offerItemOnMarket,
  loadLiveAuctions,
  purchaseNFT,
  loadAdmin,
  placeBid,
  loadAuction,
  loadBidders,
  claimPrize,
  lazyOfferItemOnMarket,
  getCoinBalance,
  changeRoyalty,
  withdrawTo,
}
