import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Canceled = () => {
  return (
    <section className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div
        className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl
      rounded-2xl shadow-lx shadow-black text-center py-5
      flex flex-col justify-center items-center space-y-2"
      >
        <FaTimes className="text-red-800" size={60} />
        <h4 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl text-white">
          Payment Failed
        </h4>
        <p className="text-gray-400">
          Unfortunately, we were unable to complete this transaction, please try
          again later.
        </p>
        <Link
          to="/wallet"
          className="bg-blue-500 hover:bg-blue-700 text-white
          font-bold py-2 px-4 rounded focus:outline-none
          focus:shadow-outline"
        >
          See wallet
        </Link>
      </div>
    </section>
  );
};
export default Canceled;
