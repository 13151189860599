import React from 'react';
import nxtlogo from '../assets/images/WhiteLogo.png';

function PartnerPage() {
  // Array of development partners
  const Devpartners = [
    {
      name: 'Nxtech Solutions',
      description:
        'A Software Engineering company based in New Zealand. We specialize in delivering software solutions to industries in a full stack development fashion',
      logo: 'Nxtech Solutions',
    },
  ];

  return (
    <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
      <div className="my-12">
        <h1 className="text-white text-3xl font-extrabold  sm:text-4xl">
          Development Partners
        </h1>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-3 gap-4 ">
        {Devpartners.map((Devpartner) => (
          // Render each development partner as a card
          <div
            key={Devpartner.name}
            className=" bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl shadow-lg rounded-lg overflow-hidden"
          >
            <div className="p-4">
              <img
                className="h-160 w-auto mx-auto"
                src={nxtlogo}
                alt="Nxtech Logo"
              />
              <h2 className="text-lg font-medium text-white mt-4 mb-2">
                {Devpartner.name}
              </h2>
              <p className="text-white text-sm">{Devpartner.description}</p>
              <p className="text-white pt-4 cursor-pointer hover:underline hover:text-blue-500">
                <a href="https://www.nxtech.co.nz/">www.nxtech.co.nz</a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PartnerPage;
