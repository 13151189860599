import React from 'react';
import Pic1 from '../assets/images/HTBPicture1.png';
import Pic2 from '../assets/images/HTBPicture2.png';
import Pic3 from '../assets/images/HTBPicture3.png';
import Pic4 from '../assets/images/HTBPicture4.png';
import Pic5 from '../assets/images/HTBPicture5.png';
import Pic6 from '../assets/images/HTBPicture6.png';

const HowToBuyAnNFT = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#Recap">
                Step by Step
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <section id="Recap" className="mb-10">
          <h2 className="text-2xl font-bold mb-2">
            A step-by-step guide to purchasing your first NFT
          </h2>
          <p>Let's walk through how to buy an NFT.</p>

          <h3 className="pt-4 pb-8 font-bold">
            1. For this example, we'll buy an NFT that's listed for sale by
            clicking the “Buy NFT” button.
          </h3>
          <img
            src={Pic3}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="py-8 font-bold">2. Connect your crypto wallet</h3>
          <img
            src={Pic4}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <p className="w-3/5 pt-5 mx-auto">
            If you haven't already connected and set up your crypto wallet,
            you'll be prompted to do so during this step.
          </p>
          <h3 className="pt-8 font-bold">3. Review fees</h3>
          <p className="pb-8">
            You may notice that your total is higher than the listed price for
            that NFT. This is due to network (“gas”) fees and, if you're paying
            with a card, processing fees. The gas fee occurs on all transactions
            on the blockchain, and the amount varies based on a variety of
            factors, such as how many people are using the network at that
            moment. Universal Collective does not receive these fees.
          </p>
          <img
            src={Pic5}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="pt-8 font-bold">4. Your purchase is complete!</h3>
          <p>
            Once you've entered all your necessary information and clicked
            “Pay”, your NFT purchase is officially complete and has been written
            to the blockchain. Congratulations on your NFT purchase!
          </p>
          <h3 className="pt-4 font-bold">5. Check out your newest NFT</h3>
          <p className="pb-8">
            After a few seconds, your brand-new NFT should be visible in your
            collection. You'll be able to see your new NFT in your Universal
            Collective profile!
          </p>
          <img
            src={Pic6}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
        </section>
      </div>
    </div>
  );
};

export default HowToBuyAnNFT;
