import { useGlobalState } from '../store';
import Artworks from '../components/Artworks';
import Empty from '../components/Empty';
import { useEffect } from 'react';
import { loadCollection } from '../services/blockchain';

const Collections = () => {
  // Accessing the minted state using useGlobalState
  const [minted] = useGlobalState('minted');
  // Accessing the unminted state using useGlobalState
  const [unminted] = useGlobalState('unminted');
  // Accessing the connectedAccount state using useGlobalState
  const [connectedAccount] = useGlobalState('connectedAccount');

  useEffect(async () => {
    if (connectedAccount) {
      // Calling the loadCollection function when the connectedAccount state changes
      await loadCollection(connectedAccount);
    }
  }, [connectedAccount]);

  return (
    <div className="w-4/5 mx-auto mt-11 ">
      {/* Conditionally render the Artworks component with the title "On-chain Collections" and the minted auctions if the minted array is not empty.
If the minted array is empty, render nothing. */}
      {minted.length > 0 ? (
        <Artworks title="On-chain Collections" auctions={minted} showOffer />
      ) : null}

      {/* Conditionally render the Artworks component with the title "Off-chain Collection" and the unminted auctions if the unminted array is not empty.
  If the unminted array is empty, render nothing. */}
      {unminted.length > 0 ? (
        <Artworks title="Off-chain Collection" auctions={unminted} lazy />
      ) : null}

      {/* Rendering the Empty component if both minted and unminted arrays are empty */}
      {minted.length < 1 && unminted.length < 1 ? <Empty /> : null}
    </div>
  );
};

export default Collections;
