import React from 'react';
import wallet from '../assets/images/wallet1.png';
import create from '../assets/images/create.png';
import sell from '../assets/images/sell.png';
import buy from '../assets/images/buynft.png';
import web3 from '../assets/images/web3v1.png';
import nft from '../assets/images/nftv1.png';
import mint from '../assets/images/minting.png';
import matic from '../assets/images/matic.png';
import gasfee from '../assets/images/gasfees.png';
import cryptocur from '../assets/images/cryptocurv1.png';
import cryptowallet from '../assets/images/cryptowallet.png';
import block from '../assets/images/blockchainv1.png';


const LearnMain = () => {
  return (
    <div>
      <Learntuc />
      <Learn />
    </div>
  );
};

const Learntuc = () => {
  return (
    <div className="w-4/5 mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-center text-3xl pb-4 font-bold text-white ">
        Your guide to The Universal Collective
      </h2>
      <h3 className="text-center text-xl pb-10 font-semibold text-white mb-8">
        In 4 easy steps!
      </h3>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
           Setup your wallet
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
                <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={wallet}
                      alt="wallet"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        The Universal Collective's tutorial
                        on how to setup your wallet.
                      </p>
                      <a href="/HowToSetUpYourWallet">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            Create your NFT
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={create}
                      alt="create"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        The Universal Collective's tutorial
                        on how to create your NFT.
                      </p>
                      <a href="/HowToCreateAnNFT">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            Sell your NFT
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={sell}
                      alt="sell"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        The Universal Collective's tutorial
                        on how to sell your NFT.
                      </p>
                      <a href="/HowToSellAnNFTOnUC">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            Buy an NFT
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={buy}
                      alt="buy"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        The Universal Collective's tutorial
                        on how to buy your NFT.
                      </p>
                      <a href="/HowToSellAnNFTOnUC">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

const Learn = () => {
  return (
    <div className="w-4/5 mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-center text-3xl pb-10 font-bold text-white mb-8">
        More about the NFT world
      </h2>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What is an NFT
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={nft}
                      alt="nft"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        A non-fungible token (NFT) is a special digital object
                        kept on a blockchain. Nearly anything can be represented
                        by NFTs.
                      </p>
                      <a href="/WhatIsAnNFT">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What is Minting
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={mint}
                      alt="mint"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        The act of adding a digital item to the blockchain is
                        known as "minting" an NFT. This proves its unchangeable
                        record of ownership and legitimacy
                      </p>
                      <a href="/WhatIsMinting">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2 text-center">
            What is the Blockchain
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={block}
                      alt="block"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        A blockchain is a decentralised ledger that is
                        distributed digitally and stores information and
                        transactions. 
                      </p>
                      <a href="/WhatistheBlockchain">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What are gas fees
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={gasfee}
                      alt="gasfee"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        Web3's gas fee is the amount paid for a blockchain
                        transaction, compensating node operators to ensure a
                        secure and unalterable record. 
                      </p>
                      <a href="/WhatAreGasFees">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What is Polygon - MATIC?
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={matic}
                      alt="matic"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        Polygon is an Ethereum sidechain with EVM support and
                        Proof-of-Stake consensus. It offers advantages over
                        Ethereum and shares compatibility. 
                      </p>
                      <a href="/WhatIsPolygon">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What is Web3
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={web3}
                      alt="web3"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        Polygon is an Ethereum sidechain enabling Web3, a
                        decentralized internet based on blockchain technology.
                        
                      </p>
                      <a href="/WhatIsWeb3">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What is cryptocurrency?
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={cryptocur}
                      alt="cryptocurrencies"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        Digital money known as cryptocurrency resides on the
                        blockchain, a decentralised network.
                      </p>
                      <a href="/WhatIsCryptocurrency">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="bg-[#9fccff] bg-opacity-10 backdrop-blur-3xl rounded-2xl shadow-lx border border-[#9fccff]/20 shadow-black py-6 px-4">
          <h3 className="text-white text-lg font-bold mb-2  text-center">
            What is a Crypto Wallet
          </h3>
          <div className="relative col-span-3 xl:col-span-4 md:order-1 md:col-span-8 ">
            <div className="flex items-center justify-center">
              <div className="group h-96 w-80 [perspective:1000px]">
              <div className="relative h-full w-full rounded-xl  transition-all duration-500 ">
                  {/* Removed as per client request = [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] */}
                  <div>
                  <div className="h-full w-full rounded-xl px-5 text-center text-slate-200 ">
                    {/* Removed as per client request = [transform:rotateY(180deg)] [backface-visibility:hidden] */}
                    <div className='flex items-center justify-center py-10'>               <img
                      className=" w-[100px] rounded-xl shadow-black/40 "
                      src={cryptowallet}
                      alt="cryptowallet"
                    /></div>
                    <div className="flex flex-col items-center justify-center min-h-full">
                      <p className="text-white leading-relaxed">
                        A crypto wallet is software that simplifies buying,
                        selling, and storing cryptocurrencies, including NFTs.
                        It allows you to send and receive assets.
                      </p>
                      <a href="/WhatIsACryptoWallet">
                        <button className="px-2 py-1 mt-2 text-sm rounded-md  bg-blue-800 hover:bg-blue-800">
                          Learn more
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LearnMain;
