import { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { setGlobalState, useGlobalState, truncate } from '../store';
import { withdrawTo } from '../services/blockchain';

// Component representing a withdrawal form
const Withdrawal = () => {
  // State variables
  const [withdrawal] = useGlobalState('withdrawal');
  const [wallet, setWallet] = useState('');
  const [percent, setPercent] = useState('');
  const [availablePercent, setAvailablePercent] = useState(100);
  const [amount, setAmount] = useState('');
  const [shares, setShares] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (shares.length < 1 || beneficiaries.length < 1 || !amount) return;

    await toast.promise(
      new Promise(async (resolve, reject) => {
        await withdrawTo(beneficiaries, shares, amount)
          .then(async () => {
            onClose();
            resolve();
          })
          .catch((error) => {
            alert(JSON.stringify(error));
            reject(error);
          });
      }),
      {
        //messages based on change
        pending: 'Transfering Ethers...',
        success: 'Ethers to addresses 👌',
        error: 'Encountered error 🤯',
      }
    );
  };
  // Close the withdrawal form and reset state
  const onClose = () => {
    setGlobalState('withdrawal', 'scale-0');
    setBeneficiaries([]);
    setShares([]);
    setAmount('');
    setAvailablePercent(100);
  };
  // Add beneficiary and percent to the list
  const addTo = () => {
    if (!percent || !wallet) return;
    if (beneficiaries.includes(wallet.toLowerCase())) return;
    if (availablePercent - percent < 0 || percent == 0) return;

    setAvailablePercent(availablePercent - percent);

    setBeneficiaries((prevState) => [wallet.toLowerCase(), ...prevState]);
    setShares((prevState) => [Number(percent), ...prevState]);
    setPercent('');
    setWallet('');
  };
  // Remove beneficiary from the list
  const removeFrom = (index) => {
    setAvailablePercent(availablePercent + Number(shares[index]));

    beneficiaries.splice(index, 1);
    shares.splice(index, 1);

    setBeneficiaries((prevState) => [...prevState]);
    setShares((prevState) => [...prevState]);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex
      items-center justify-center bg-black bg-opacity-50
      transform transition-transform duration-300 ${withdrawal}`}
    >
      <div
        className="bg-[#151c25] shadow-xl shadow-[#63a2c9] rounded-xl
        w-11/12 md:w-2/5 h-7/12 p-6"
      >
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex justify-between items-center text-slate-500">
            <p className="font-semibold">Send to Wallets</p>
            <button
              onClick={onClose}
              type="button"
              className="border-0 bg-transparent focus:outline-none"
            >
              <FaTimes />
            </button>
          </div>

          <div
            className="flex justify-between items-center
          bg-gray-800 rounded-xl mt-5"
          >
            <input
              className="block w-full bg-transparent
              border-0 text-sm text-slate-500 focus:outline-none
              focus:ring-0"
              type="text"
              name="wallet"
              pattern="0x[a-fA-F0-9]{40}"
              minLength={42}
              maxLength={42}
              placeholder="Wallet (ETH)"
              onChange={(e) => setWallet(e.target.value)}
              value={wallet}
            />
          </div>

          <div
            className="flex justify-between items-center
          bg-gray-800 rounded-xl mt-5"
          >
            <input
              className="block w-full bg-transparent
              border-0 text-sm text-slate-500 focus:outline-none
              focus:ring-0"
              type="number"
              step={0.5}
              min={1}
              max={availablePercent}
              name="percent"
              placeholder={`Percent (1 - ${availablePercent})`}
              onChange={(e) => setPercent(e.target.value)}
              value={percent}
            />
          </div>

          <div className="flex justify-start items-center space-x-1 mt-5">
            {beneficiaries.slice(0, 3).map((beneficiary, i) => (
              <div
                key={i}
                className="p-2 rounded-full text-gray-500 bg-gray-200 font-semibold
                flex items-center w-max cursor-pointer active:bg-gray-300
                transition duration-300 ease space-x-2 text-xs"
              >
                <span>
                  {truncate(beneficiary, 4, 4, 11)} %{shares[i]}
                </span>
                <button
                  type="button"
                  onClick={() => removeFrom(i)}
                  className="bg-transparent hover focus:outline-none"
                >
                  <FaTimes />
                </button>
              </div>
            ))}

            {beneficiaries.length - beneficiaries.slice(0, 3).length > 0 ? (
              <div
                className="p-2 rounded-full text-gray-500 bg-gray-200 font-semibold
                flex items-center w-max cursor-pointer active:bg-gray-300
                transition duration-300 ease space-x-2 text-xs"
              >
                <span>
                  + {beneficiaries.length - beneficiaries.slice(0, 3).length}
                </span>
              </div>
            ) : null}
          </div>

          {beneficiaries.length > 0 ? (
            <div
              className="flex justify-between items-center
          bg-gray-800 rounded-xl mt-5"
            >
              <input
                className="block w-full bg-transparent
                border-0 text-sm text-slate-500 focus:outline-none
                focus:ring-0"
                type="number"
                step={0.001}
                min={0.001}
                name="amount"
                placeholder="Amount e.g 0.01"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </div>
          ) : null}

          <div className="flex justify-between items-center mt-5">
            <button
              type="submit"
              disabled={beneficiaries.length < 1}
              className="bg-blue-500 p-2 px-6 rounded-full text-white shadow-md
                shadow-gray-300 transform transition-transform duration-30 w-fit"
            >
              Send Ethers
            </button>

            <button
              type="button"
              onClick={addTo}
              className="border border-blue-500 text-blue-500 p-2 px-6 rounded-full shadow-md
            shadow-gray-300 transform transition-transform duration-30 w-fit"
            >
              Add to List
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Withdrawal;
