import { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import picture6 from '../assets/images/picture6.png';
import {
  lazyOfferItemOnMarket,
  offerItemOnMarket,
} from '../services/blockchain';
import { setGlobalState, useGlobalState } from '../store';
import { mintNFT } from '../services/api';

const OfferItem = () => {
  // State variables
  const [offerModal] = useGlobalState('offerModal');
  const [auction] = useGlobalState('auction');
  const [connectedAccount] = useGlobalState('connectedAccount');

  const [expiresAt, setExpiresAt] = useState('');
  const [biddable, setBiddable] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!expiresAt || !biddable) return;

    const params = {
      ...auction,
      owner: connectedAccount,
      biddable: biddable == 'true',
      expiresAt: new Date(expiresAt).getTime(),
    };
    // Use toast.promise to display messages while offering the auction
    await toast.promise(
      new Promise(async (resolve, reject) => {
        if (params.tokenId) {
          // If the auction already has a tokenId, call offerItemOnMarket
          await offerItemOnMarket(params)
            .then(() => {
              // Close the offer form
              onClose();
              // Reset the form fields
              resetForm();
              // Refresh the page
              window.location.reload();
              resolve();
            })
            .catch((error) => {
              alert(JSON.stringify(error));
              reject(error);
            });
        } else {
          // If the auction doesn't have a tokenId, call lazyOfferItemOnMarket and mintNFT
          await lazyOfferItemOnMarket(params)
            .then(async () => {
              await mintNFT(objectToFormData(params))
                .then(() => {
                  onClose();
                  // Close the offer form
                  resetForm();
                  // Reset the form fields
                  window.location.reload();
                  // Refresh the page
                  resolve();
                })
                .catch((error) => reject(error));
            })
            .catch((error) => {
              alert(JSON.stringify(error));
              reject(error);
            });
        }
      }),
      {
        // message on change
        pending: 'Offering Auction...',
        success: 'Auction offered...',
        error: 'Encoutered an error, check console',
      }
    );
  };
  // Convert object to FormData
  const objectToFormData = (obj) => {
    const formData = new FormData();
    for (let key in obj) {
      formData.append(key, obj[key]);
    }
    return formData;
  };
  // Close the offer form
  const onClose = () => {
    resetForm();
    setGlobalState('offerModal', 'scale-0');
  };
  // Reset the form fields
  const resetForm = () => {
    setExpiresAt('');
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
      justify-center bg-black bg-opacity-50 transform
      transition-transform duration-300 z-20 ${offerModal}`}
    >
      <div
        className="bg-[#151c25] shadow-xl rounded-xl
        w-11/12 sm:w-2/5 h-7/12 p-6"
      >
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex justify-between items-center text-gray-400">
            <p className="font-semibold italic">Offer {auction.name}</p>
            <button
              type="button"
              onClick={onClose}
              className="border-0 bg-transparent focus:outline-none"
            >
              <FaTimes />
            </button>
          </div>

          <div className="flex justify-center items-center mt-5">
            <div className="shrink-0 rounded-xl overflow-hidden h-20 w-20">
              <img
                src={auction.image || picture6}
                alt="Artwork"
                className="h-full w-full object-cover cursor-pointer"
              />
            </div>
          </div>

          <div className="flex justify-between items-center bg-gray-800 rounded-xl mt-5">
            <input
              type="datetime-local"
              min={new Date().toISOString().slice(0, 16)}
              max={new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
              className="block w-full text-sm text-slate-500 focus:outline-none
              cursor-pointer focus:ring-0 bg-transparent border-0 px-4 py-2"
              placeholder="Days E.g 7"
              name="expiresAt"
              value={expiresAt}
              onChange={(e) => setExpiresAt(e.target.value)}
              required
            />
          </div>

          <div className="flex justify-between items-center bg-gray-800 rounded-xl mt-5">
            <select
              name="expiresAt"
              className="block w-full text-sm text-slate-500 focus:outline-none
              cursor-pointer focus:ring-0 bg-transparent border-0 px-4 py-2"
              placeholder="Days E.g 7"
              value={biddable}
              onChange={(e) => setBiddable(e.target.value)}
              required
            >
              <option value="" hidden>
                Select Biddability
              </option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>

          <button
            type="submit"
            className="flex justify-center items-center
            w-full text-white text-md bg-blue-500 mt-5
            py-2 px-5 rounded-full drop-shadow-xl border border-transparent
            hover:bg-transparent hover:text-white focus:ring-0
            hover:border hover:border-blue-500 focus:outline-none"
          >
            Offer item
          </button>
        </form>
      </div>
    </div>
  );
};

export default OfferItem;
