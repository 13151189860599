import React from 'react';
import CreateNFT1 from '../assets/images/CreateNFT1.png';
import CreateNFT2 from '../assets/images/CreateNFT2.png';
import CreateNFT3 from '../assets/images/CreateNFT3.png';
import CreateNFT4 from '../assets/images/CreateNFT4.png';
import CreateNFT5 from '../assets/images/CreateNFT5.png';
import CreateNFT6 from '../assets/images/CreateNFT6.png';

const HowToCreateAnNFT = () => {
  return (
    <div className="flex flex-col md:flex-row text-white px-4 md:px-40 lg:px-56">
      <div className="w-full md:w-1/4 hidden md:block">
        <div className="sticky top-10">
          <h2 className="text-lg font-bold my-4">Contents</h2>
          <ul className="space-y-2">
            <li>
              <a className="hover:text-blue-400" href="#instructions">
                What are the step-by-step instructions for creating an NFT using
                Universal Collective?
              </a>
            </li>
            <li>
              <a className="hover:text-blue-400" href="#Why">
                Why should I create my NFTs using Universal Collective?
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-3/4 ml-0 md:ml-8">
        <h1 className="text-3xl font-extrabold mb-4">
          How to Create an NFT on Universal Collective
        </h1>
        <section id="instructions" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            What are the{' '}
            <span className="text-blue-500">
              step-by-step instructions for creating an NFT
            </span>{' '}
            using Universal Collective?
          </h2>
          <p>Universal Collective makes it simple and fast to create an NFT.</p>
          <h3 className="pt-4 pb-8 font-bold px-10">
            Step 1: Ensure your wallet is connected.
          </h3>
          <img
            src={CreateNFT1}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="pt-8 pb-8 font-bold px-10">
            Step 2: Click on the Create NFT Button on the home page.
          </h3>
          <img
            src={CreateNFT2}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="pt-4 font-bold px-10">Step 3: NFT Form</h3>
          <p className="pt-2 pb-5 px-10">
            After clicking, a screen where you may upload your NFT item's file
            and give it a name, price and description will appear.
          </p>
          <img
            src={CreateNFT3}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="pt-8 font-bold px-10">Step 4: NFT Form submit</h3>
          <p className="pt-2 pb-5 px-10">
            After filling out the form details hit “Mint now” and the image will
            be added to your collection.
          </p>
          <img
            src={CreateNFT4}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="pt-8 font-bold px-10">
            Step 5: Added to your collection
          </h3>
          <p className="pt-2 pb-8 px-10">
            Once completed, you'll see a pop-up that indicates your NFT has been
            created and allows you to push it onto the live chain.
          </p>
          <img
            src={CreateNFT5}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <h3 className="pt-8 font-bold px-10">Step 6: Off-Chain Collection</h3>
          <img
            src={CreateNFT6}
            alt="Image 1"
            className="rounded-xl shadow-lg shadow-blue-500/30 mx-auto"
          />
          <p className="pt-8">
            In your collections tab you will now be able to view your newly
            created NFT, this is where you can offer or delete from your
            collection.
          </p>
        </section>
        <section id="Why" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            Why should I create my{' '}
            <span className="text-blue-500">
              NFTs using Universal Collective?
            </span>
          </h2>
          <p>
            The easy, straightforward, and user-friendly development process
            provided by Universal Collective enables producers to produce
            collections that make them stand out. Speaking of standing out, we
            think Universal Collective is the ideal platform for creators to
            list their NFTs for sale because it has the broadest distribution.
            We are the first and largest internet marketplace for NFTs in the
            world, which explains this. We take great satisfaction at Universal
            Collective in making the NFT creation and minting.{' '}
          </p>
        </section>
      </div>
    </div>
  );
};

export default HowToCreateAnNFT;
